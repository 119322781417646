import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { formatTime, getSafeValue } from "utils";
import uuid4 from "uuid4";
import ActiveBox from "./ActiveBox";
import Style from "./Updates.module.scss";

function Updates({ isPublic, color }: any) {
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const leadCampaignEmails = getSafeValue(publicData, 'lead_campaign.lead_campaign_emails', false)

  const [emailData, setEmailData] = useState<any>([{
    image: getSafeValue(data, 'alias_image', '/assets/images/leadProfile/user.svg'),
    name: getSafeValue(data, 'alias_image_upload.custom_name', 'Chris Hooper'),
    sent_at: 'Today @12.22pm',
    is_new: true,
    subject: 'Hello Cliff, thank you for allowing us to quote your project!',
    message: `<h1>Hello Cliff, thank you for allowing us to quote your project!</h1><p>Here's your tailored tinting quote using our top-tier Kepler window film.</p><p>This film ensures superior heat, glare, and UV protection. Review your quote and click "approve" if you wish to continue. We look forward to elevating your drive with our premium tinting.</p>`
  }])

  useEffect(() => {
    if (leadCampaignEmails !== false) {
      let campaignEmails = []
      for (const emails of leadCampaignEmails) {
        campaignEmails.push({
          image: getSafeValue(data, 'alias_image', '/assets/images/leadProfile/user.svg'),
          name: getSafeValue(data, 'alias_image_upload.custom_name', 'Chris Hooper'),
          send_at: emails.send_at ? formatTime(emails.send_at) : '',
          is_new: true,
          subject: emails.content_subject,
          message: emails.content
        })
      }
      setEmailData(campaignEmails)
    }
  }, [publicData])

  return (emailData.map((data: any, key: number) => (
    <Box className={Style.mainContainer} key={uuid4()} mb={'20px'}>
      <Box className={Style.flexRow}>
        <Box className={Style.userInfo}>
          <Box className={Style.useBox} borderColor={color}>
            <img
              src={data.image}
              alt={data.name}
              className={Style.userImg}
            />
          </Box>
          <Box>
            <Typography variant="tooltipText" fontWeight={'500'} color={'#838383'}>
              Sent by{" "}
              <Typography variant="bodyTextNormal" fontWeight={'700'} color={color}>{data.name}</Typography>
            </Typography>
            <Typography variant="tooltipText" fontWeight={'700'} color={color} component={'p'} mt={'5px'}>{data.send_at}</Typography>
          </Box>
        </Box>
        {(key === 0 && data.is_new === true) && <Typography className={Style.nexText}>New</Typography>}
      </Box>
      <Box className={Style.paraContainer} dangerouslySetInnerHTML={{ __html: data.message }} />
      {key === 0 && <Box mt={3}>
        <ActiveBox isPublic={isPublic} color={color} />
      </Box>}
    </Box>
  )))
}

export default Updates;
