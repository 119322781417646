import { Box, Typography } from "@mui/material";
import { ButtonBox, RoundButton } from "components/Ui/Button/Button";
import { handleColor } from "components/Ui/Button/Tage";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getSafeValue } from "utils";
import Style from "./../Updates/Updates.module.scss";
import Highlights from "./Highlights";

function Projects({ isPublic, color }: any) {
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const projects = useAppSelector((state) => state.leadProfile.k_profile_projects);
  const navigate = useNavigate()


  let projectType = `${getSafeValue(publicData, 'project.type', '')} Window Tinting`
  if (getSafeValue(publicData, 'project.services', []).length === 1 && getSafeValue(publicData, 'project.services[0].type', '') === 'Other') {
    projectType = getSafeValue(publicData, 'project.services[0].service_title', 'Other')
  }

  if (getSafeValue(publicData, 'project.services', []).length > 1) {
    projectType = `${projectType} +${getSafeValue(publicData, 'project.services', []).length - 1} More`
  }

  const handleNavigate = (campaign_id: any, status: any) => {
    if (isPublic) {
      const project_status = getSafeValue(publicData, 'project.status', '')
      if (project_status === 'Scheduled') {
        navigate(`/profile/${campaign_id}/quote-overview`)
      } else {
        navigate(`/profile/${campaign_id}/quote`)
      }
    }
  };

  return (
    <Box>
      <Highlights />
      <Box pt={2}>
        <Typography padding={'0 20px'} variant="heading3" component={'h3'}>All Projects</Typography>
        {projects.map((project: any) => (
          <Box className={Style.activeBox} mt={2}>
            <Box className={Style.statusInfo} pb={3}>
              <Box className={Style.flex1}>
                <Typography variant="heading5" component={'h5'} fontWeight={'500'} color={handleColor(project.status).background}>{project.status}</Typography>
              </Box>
              <Box className={Style.flex2}>
                <Typography variant={'bodyTextSmall'} fontWeight={'700'} color={handleColor(project.status).background}>{project.status}</Typography>
                <Typography variant={'bodyTextSmall'} fontWeight={'700'} color={'#000000'}>{project.created_at} {project.completed_at}</Typography>
              </Box>
            </Box>
            <Box className={Style.projectInfo}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                  <Typography variant="heading3" component={'h3'}>{project.name}</Typography>
                  <Typography variant="inputText" fontWeight={'400'}>{projectType}</Typography>
                </Box>
                <Box>
                  <RoundButton
                    sx={{ backgroundColor: color, ':hover': { backgroundColor: color } }}
                    onClick={() => handleNavigate(project.campaign_id, project.status)}
                  >View Quote</RoundButton>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box pt={2}>
        <ButtonBox sx={{ backgroundColor: color, ':hover': { backgroundColor: color } }} fullWidth className="SeaGreen">
          Get Another Quote
        </ButtonBox>
      </Box>
    </Box>
  );
}

export default Projects;
