import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { themeColor } from "theme/color";

interface CounterState {
  isOpen: boolean;
  isArrow: boolean;
  placeName: string;
  bgColor: string;
  isBack: false;
  navigate: string;
  mainContainerPadding: string;
  settings: string | boolean;
}

const initialState: CounterState = {
  isOpen: false,
  placeName: "",
  isArrow: false,
  bgColor: themeColor.mainContainer,
  isBack: false,
  navigate: '',
  mainContainerPadding: '25px !important',
  settings: false
};

export const uiReducer = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    openMenu: (state) => {
      state.isOpen = true;
    },
    closeMenu: (state) => {
      state.isOpen = false;
    },
    changePlaceName: (state, action: PayloadAction<any>) => {
      state.placeName = action.payload.name;
      state.isArrow = action.payload.isArrow;
      state.navigate = action.payload.navigate;
      state.settings = action.payload.settings;
    },
    changeBgColor: (state, action: PayloadAction<any>) => {
      state.bgColor = action.payload;
    },
    changeMainContainerPadding: (state, action: PayloadAction<any>) => {
      state.mainContainerPadding = action.payload;
    },
  },
});

export const { toggle, openMenu, closeMenu, changePlaceName, changeBgColor, changeMainContainerPadding } =
  uiReducer.actions;

export default uiReducer.reducer;
