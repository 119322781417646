/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, FormControlLabel, Grid, Typography } from "@mui/material";
import ContactAPI from 'api/resources/contact';
import UserAPI from "api/resources/user";
import addIconBlue from "assets/add_square_blue.svg";
import SelectDropdown from 'components/FormInputs/Select/Index';
import InnerLoader from "components/Loading/InnerLoader";
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import TextInput from "components/Ui/TextInput/TextInput";
import { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { getFullAddress, getSafeValue, isOnlyCityPresent } from 'utils';
import Style from "./ProjectAddress.module.scss";

const renderDropdownValue = (
  value: any,
  locationOptions: any[],
  allLocations: any[],
  setAddNewAddress: Function,
  isOnlyCityPresent: Function
) => {
  const DEFAULT_ADDRESS_LABEL = 'Add new contact address';

  let data = DEFAULT_ADDRESS_LABEL;
  let label = DEFAULT_ADDRESS_LABEL;
  let completeAddress = true;

  if (value === "new") {
    data = label = DEFAULT_ADDRESS_LABEL;
  } else if (value === "workshop") {
    data = label = 'Workshop';
  } else {
    const location = locationOptions.find((option: any) =>
      typeof value === 'string' && typeof option.label === 'string'
        ? option.label.replace(/\(.*?\)/, '').trim() === value
        : option.value === value
    );

    if (!location) {
      data = label = DEFAULT_ADDRESS_LABEL;
      setAddNewAddress(true);
    } else {
      const locationAddress = allLocations.find(
        (_location: any) => _location?.address_id === location?.value
      );
      completeAddress = !isOnlyCityPresent(locationAddress?.address);
      data = location.label
      label = location.label;
      setAddNewAddress(!completeAddress && data !== 'Workshop');
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="0 !important"
      textTransform="capitalize"
      gap="10px"
      title={label}
      overflow={'hidden'}
    >
      <Typography><HiOutlineLocationMarker size={25} /></Typography>
      {data === DEFAULT_ADDRESS_LABEL ? (
        <Typography variant="linkText" onClick={() => setAddNewAddress(true)}>
          {DEFAULT_ADDRESS_LABEL}
        </Typography>
      ) : data}
      {!completeAddress && data !== 'Workshop' && (
        <Typography
          variant="linkText"
          fontSize="12px"
          onClick={() => setAddNewAddress(true)}
        >
          Add Full Address
        </Typography>
      )}
    </Box>
  );
};

const AddressDropdown = ({ handleUpdateLocation, location, contact_id = '', is_mobile, fullWidth = false }: any) => {
  const [scheduleAddress, setScheduleAddress] = useState<any>({ ...location, save_address_as_contact_address: getSafeValue(location, 'save_address_as_contact_address', true) })
  const [isLoading, setIsLoading] = useState<any>(false)
  const [addNewAddress, setAddNewAddress] = useState<any>(getFullAddress(location) ? false : (is_mobile ? true : false))
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(location.id ? location.id : (is_mobile ? (getFullAddress(location)) : 'Workshop'));
  const [allLocations, setAllLocations] = useState<any>([]);

  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    if (getFullAddress(location)) {
      setSelectedAddress(location.id ? location.id : ((location || is_mobile) ? (getFullAddress(location)) : 'Workshop'))
    }
    setScheduleAddress({ ...location, save_address_as_contact_address: getSafeValue(location, 'save_address_as_contact_address', true) })
  }, [location])

  const handleLocationUpdate = (location: any) => {
    setScheduleAddress(location)
    handleUpdateLocation(location)
  }

  const handleAddressChange = async (name: any, value: any) => {
    let location
    if (name === 'state_province') {
      if (value === null) {
        setCities([])
      } else {
        await getCities(value)
      }
      location = { ...scheduleAddress, [name]: value, city: '', mobile_install: true }
      setScheduleAddress(location)
    } else {
      location = { ...scheduleAddress, [name]: value, mobile_install: true }
      setScheduleAddress(location)
    }

    handleLocationUpdate(location)
  }

  const getLocation = async () => {
    setIsLoading(true)
    const storeLocations: any = await UserAPI.getBusinessLocations(contact_id)
    const checkLocationAlreadyExists = storeLocations.data.filter((storeLocation: any) => {
      return getFullAddress(storeLocation.address) === getFullAddress(location)
    })
    let allStoreLocations = [...storeLocations?.data]
    if (checkLocationAlreadyExists.length === 0) {
      allStoreLocations = [{
        name: (location.id || is_mobile) ? 'Customer Address' : 'Workshop',
        address_id: location.id ? location.id : (is_mobile ? getFullAddress(location) : 'Workshop'),
        address: getSafeValue(location, 'city', false) ? location : 'No address',
        organisation_id: location.organisation_id
      }, ...storeLocations?.data]
    }

    setAllLocations(allStoreLocations)
    setIsLoading(false)

    const options: any = allStoreLocations.filter((address: any) => address.address_id !== '').map((location: any) => ({
      value: location.address_id,
      label: (location.address_id === 'Workshop' || location.address_id === getFullAddress(location)) ? location.address_id : `(${location.name}) ${getFullAddress(location.address)}`,
    }));
    options.push({
      label: (
        <Box display={'flex'} gap={'5px'}>
          <img src={addIconBlue} alt="Add Icon" />
          <Typography variant='linkText'>Add New Contact Address</Typography>
        </Box>
      ),
      value: "new",
    });

    setLocationOptions(options);
  }

  const handleLocationChange = (event: any) => {
    setSelectedAddress(event.target.value)
    if (event.target.value === 'new') {
      setAddNewAddress(true)
      setScheduleAddress({ save_address_as_contact_address: getSafeValue(location, 'save_address_as_contact_address', true) })
      handleLocationUpdate({ ...location?.address, mobile_install: true })
    } else {
      setAddNewAddress(false)
      const location = allLocations.find((location: any) => location.address_id === event.target.value);
      setScheduleAddress(location?.address)
      handleLocationUpdate({ ...location?.address, mobile_install: event.target.value === 'Workshop' ? false : true })
    }
  }

  useEffect(() => {
    getState()
  }, [])

  const [state, setState] = useState([])
  const getState = async () => {
    const allState = await ContactAPI.getState()
    setState(allState.data)
  }

  const [cities, setCities] = useState([])
  const getCities = async (state: any) => {
    const allState = await ContactAPI.getCity(state)
    setCities(allState.data)
  }

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto' }}>
      {isLoading && <InnerLoader height="auto" />}
      <Box className={Style.event}>
        {isLoading === false && <SelectDropdown
          className={Style.selectLocation}
          name="location"
          options={locationOptions}
          value={selectedAddress}
          onChange={handleLocationChange}
          renderValue={(value: any) =>
            renderDropdownValue(
              value,
              locationOptions,
              allLocations,
              setAddNewAddress,
              isOnlyCityPresent
            )
          }
        />}
        {addNewAddress &&
          <Grid container spacing={2} className={Style.customerInfoForm} mt={'2px'}>
            <Grid item xs={12}>
              <Typography variant="title1" fontWeight={600}>Add Address</Typography>
              <FormControlLabel
                sx={{ margin: '15px 0', '& span': { fontSize: '15px !important' } }}
                control={
                  <BpCheckbox
                    onChange={(e: any) => handleAddressChange("save_address_as_contact_address", e.target.checked)}
                    checked={scheduleAddress?.save_address_as_contact_address}
                  />
                }
                label="Save this as the primary contact address"
              />
              <TextInput
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.street_address}
                fullWidth
                placeholder="Street Address"
                name="street_address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                placeholder="2nd Line"
                name="second_line"
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.second_line}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={state}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('state_province', getSafeValue(value, 'name', value))}
                onInputChange={(_, newInputValue: string) => handleAddressChange('state_province', newInputValue)}
                value={getSafeValue(scheduleAddress, 'state_province', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    className={Style.InputBorder}
                    size="small"
                    placeholder='State'
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('city', getSafeValue(value, 'name', value))}
                onInputChange={(_, newInputValue: string) => handleAddressChange('city', newInputValue)}
                value={getSafeValue(scheduleAddress, 'city', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextInput
                    className={Style.InputBorder}
                    {...params}
                    size="small"
                    placeholder='City'
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                placeholder="Zip"
                name="zipcode"
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.zipcode}
              />
            </Grid>
          </Grid>
        }
      </Box>
    </Box>
  );
};

export default AddressDropdown;
