/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import LeadProfileApi from "api/resources/leadProfile";
import Edit from "assets/icon/edit_icon_square.svg";
import UpdateCover from "components/Modal/UpdateCover/UpdateCover";
import UpdateProfile from "components/Modal/UpdateProfile/UpdateProfile";
import { RoundButton } from "components/Ui/Button/Button";
import { httpService } from "helper/httpService";
import { useEffect, useState } from "react";
import { HiCamera } from "react-icons/hi";
import { LuSave } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { activeTabKProfile, contactProjects, refreshRequest, selectTabs } from "redux/reducers/leadProfileReducer";
import AboutUs from "./AboutUs";
import Style from "./AutomotiveProfile.module.scss";
import Contact from "./Contact";
import Projects from "./Projects";
import TabBar from "./TabBar/TabBar";
import Updates from "./Updates";
import { getSafeValue } from "utils";
import moment from "moment";

function AutomotiveProfile({ isPublic }: any) {
  const dispatch = useAppDispatch();
  const [openProfile, setOpenProfile] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const select = useAppSelector((state: any) => state.leadProfile.select);
  const color = useAppSelector((state: any) => state.leadProfile.primaryColor);
  const k_profile_tab = useAppSelector((state: any) => state.leadProfile.k_profile_tab);
  const projects = useAppSelector((state) => state.leadProfile.k_profile_projects);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);

  const [companyName, setCompanyName] = useState("");
  const [companyEditName, setCompanyEditName] = useState("");
  const [companyLink, setCompanyLink] = useState("");
  const [companyEditLink, setCompanyEditLink] = useState("");
  const handleClose = () => {
    setOpenProfile(false);
    setOpenCover(false);
  };

  useEffect(() => {
    dispatch(selectTabs("automotive"));
  }, []);

  useEffect(() => {
    if (publicData) {
      const { contact_id } = publicData
      getContactProject(contact_id)
    }
  }, [publicData])

  useEffect(() => {
    if (data) {
      setCompanyName(data.company_name);
      setCompanyLink(data.company_link);
    }
  }, [data]);

  const handleSave = () => {
    if (isEdit) {
      const para = {
        ...data,
        company_name: companyEditName ? companyEditName : companyName,
        company_link: companyEditLink ? companyEditLink : companyLink,
      };
      delete para.alias_image;
      delete para.cover_picture;
      delete para.logo;
      httpService(
        () => LeadProfileApi.updateProfile(select, para),
        refreshRequest
      );
      setCompanyName(para.company_name);
      setCompanyLink(para.company_link);
    }
    setIsEdit(!isEdit);
  };

  const handleTabChange = (tab: any) => {
    dispatch(activeTabKProfile(tab))
  }

  const getContactProject = async (contact_id: any) => {
    if (projects.length === 0) {
      try {
        const data = await LeadProfileApi.getKProfileContactProject(contact_id)

        const allProjects = getSafeValue(data, 'data.data', []).filter((project: any) => project.type === 'Automotive').map((project: any) => {
          return {
            campaign_id: getSafeValue(project, 'your_quote_lead_campaign_email_uuid', ''),
            status: getSafeValue(project, 'status', ''),
            created_at: getSafeValue(project, 'created_at', '') ? moment(getSafeValue(project, 'created_at', '')).format('MM.DD.YY') : '',
            name: `${getSafeValue(project, 'type_details.make', '---')} ${getSafeValue(project, 'type_details.model', '---')} (${getSafeValue(project, 'type_details.year', '---')})`,
            type: `${getSafeValue(project, 'type', '')} Window Tinting`,
            completed_at: getSafeValue(project, 'completed_at', '') !== '' ? moment(getSafeValue(project, 'completed_at', '')).format('MM.DD.YY') : '',
          }
        })
        dispatch(contactProjects(allProjects))
      } catch (error) {
        console.log(error)
      }

    }
  }

  return (
    <Box>
      <Box className={Style.header}>
        <Box
          className={Style.banner}
          sx={{
            backgroundImage: `url(${data?.cover_picture ? data.cover_picture : "/assets/images/leadProfile/banner.png"})`,
          }}
        >
          {!isPublic && (
            <Box className={Style.bannerAdd} onClick={() => setOpenCover(true)}>
              <HiCamera color={"#b3b3b3"} size={25} />
              <Typography className={Style.bannerAddText} color={"#b3b3b3"}>
                Change Cover Photo
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={Style.bannerContent}>
          <Box className={Style.containerLogo}>
            <Box className={Style.logoMain}>
              <Box className={Style.logoContainer}>
                <Box className={Style.logoBox}>
                  {data && <img src={data?.logo} alt="logo" />}
                </Box>
                {!isPublic && (
                  <Box
                    className={Style.addlogo}
                    onClick={() => setOpenProfile(true)}
                  >
                    <HiCamera color={"#000"} size={20} />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography
                  className={`${Style.text1} ${isEdit && Style.editInput}`}
                  contentEditable={isEdit}
                  onInput={(e: any) => setCompanyEditName(e.currentTarget.textContent)}
                >
                  {companyName || "Company Name"}
                </Typography>
                <Box className={Style.flexBox}>
                  <Typography
                    className={`${Style.text2} ${isEdit && Style.editInput}`}
                    contentEditable={isEdit}
                    onInput={(e: any) => setCompanyEditLink(e.currentTarget.textContent)}
                  >
                    {companyLink || "Company Link"}
                  </Typography>

                  {!isPublic && (
                    <Box className="cursor" onClick={() => handleSave()}>
                      {!isEdit ? (<img src={Edit} alt="edit" />) : (<LuSave size={20} />)}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <RoundButton sx={{ backgroundColor: color, ':hover': { backgroundColor: color } }} onClick={() => { }}>Get New Quote</RoundButton>
          </Box>
          <TabBar
            activeTab={k_profile_tab}
            setActiveTab={handleTabChange}
            color={color}
          />
        </Box>
      </Box>
      <Box pt={2}>
        {k_profile_tab === "Updates" && (
          <Updates isPublic={isPublic} color={color} />
        )}
        {k_profile_tab === "Projects" && (
          <Projects isPublic={isPublic} color={color} />
        )}
        {k_profile_tab === "About Us" && (
          <AboutUs isPublic={isPublic} color={color} />
        )}
        {k_profile_tab === "Contact" && (
          <Contact isPublic={isPublic} color={color} />
        )}
      </Box>

      {!isPublic && (<>
        <UpdateProfile open={openProfile} handleClose={handleClose} />
        <UpdateCover open={openCover} handleClose={handleClose} type={select} />
      </>)}
    </Box>
  );
}

export default AutomotiveProfile;
