import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import ContactIcon from 'assets/icon/icon _call.svg';
import LocationIcon from 'assets/icon/icon_location.svg';
import EnailIcon from 'assets/icon/icon_sms.svg';
import UserIcon from 'assets/icon/icon_user.svg';
import classNames from 'classnames';
import { PrimaryButton } from 'components/Ui/Button/Button';
import { Tag } from "components/Ui/Button/Tage";
import _ from 'lodash';
import { getFullAddress } from 'utils';
import styles from "./ContactForm.module.scss";

function SuggestedContactPopup({
  setSuggestedContact,
  suggestedContact,
  suggestedContacts,
  onSelectContactChange,
  handleUseContact,
  buttonText
}: any) {

  return <Dialog
    open={suggestedContact !== null || false}
    onClose={() => setSuggestedContact(null)}
    maxWidth='xs'
    fullWidth={true}
    className={styles.exitDialog}
    disableEnforceFocus
    scroll="paper">
    <DialogTitle>
      <CloseIcon className={classNames(styles.closeButton, styles.closeIcon)} onClick={() => setSuggestedContact(null)} />
      <Typography variant="h6" component={'p'} className={styles.modelPopupTitle}>We've identified a potential match in our system. Is this your customer?</Typography>
    </DialogTitle>
    <DialogContent>
      {suggestedContact && (
        <>
          <Box className={styles.contactSelect}>
            <Select
              className={styles.inputFieldSelect}
              labelId={`contact_${suggestedContact?.id}`}
              value={suggestedContact?.id}
              onChange={onSelectContactChange}
              variant='outlined'
            >
              {suggestedContacts.map((item: any, index: number) =>
                <MenuItem key={index} value={item.id}>{item.name} {item.phone_number}</MenuItem>
              )}
            </Select>
          </Box>
          <Box className={styles.contactDetails}>
            <Box>
              <Box className={styles.infoWrapper}>
                <Box className={styles.infoIcon}>
                  <img src={UserIcon} alt='' />
                </Box>
                <Box className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.name}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.infoWrapper}>
                <Box className={styles.infoIcon}>
                  <img src={ContactIcon} alt='' />
                </Box>
                <Box className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.phone_number}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.infoWrapper}>
                <Box className={styles.infoIcon}>
                  <img src={EnailIcon} alt='' />
                </Box>
                <Box className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.email}
                  </Typography>
                </Box>
              </Box>
              {!_.isEmpty(suggestedContact.address) &&
                <Box className={styles.infoWrapper}>
                  <Box className={styles.infoIcon}>
                    <img src={LocationIcon} alt='' />
                  </Box>
                  <Box className={styles.info}>
                    <Typography variant="subtitle1" className={styles.infoTitle}>
                      {getFullAddress(suggestedContact.address)}
                    </Typography>
                  </Box>
                </Box>
              }
            </Box>
          </Box>
          <Box className={styles.contactProjects}>
            {suggestedContact.projects.length > 0 ? suggestedContact.projects.map(
              (project: any, index: number) =>
                <Box key={index} className={styles.quoteWrapper}>
                  <Box className={styles.quoteInfo}>
                    <Box>
                      {project.type === 'Automotive' &&
                        <Typography variant="subtitle2" className={styles.infoCaption}>
                          {project.type_details.make}{' '}
                          {project.type_details.model}
                          ({project.type_details.year})
                        </Typography>
                      }
                      {project.type === 'Architectural' &&
                        <Typography variant="subtitle2" className={styles.infoCaption}>
                          {project.type_details.building_type} {project.type_details.service_type}
                        </Typography>
                      }
                    </Box>
                    <Box>
                      <Tag label={project.status} className={project.status} />
                    </Box>
                  </Box>
                </Box>
            ) : <Box className={styles.quoteWrapper}>
              <Typography variant="subtitle2" textAlign={'center'}>No Job added yet for this Customer</Typography>
            </Box>}
          </Box>
        </>
      )}
    </DialogContent>
    <DialogActions className={styles.exitDialogActions}>
      <PrimaryButton
        fullWidth
        className={styles.useContactBtn}
        onClick={handleUseContact}
        variant="contained"
      >{buttonText}</PrimaryButton>
    </DialogActions>
  </Dialog>
}


export default SuggestedContactPopup;