import { Box, Typography } from "@mui/material";
import infoCircle from 'assets/info_circle.svg'
import Style from "./AdditionalOptionTooltip.module.scss";

function AdditionalOptionTooltip({ color, vin, license }: any) {
  return (<Box position={'relative'} className={Style.tooltipBox}>
    <img src={infoCircle} alt="info circle" />
    <Box className={Style.tooltip}>
      <Typography variant="h6" className={Style.title}>Additional Details</Typography>
      {color && <Typography variant="h6" className={Style.detail}>Color: {color}</Typography>}
      {vin && <Typography variant="h6" className={Style.detail}>Vin: {vin}</Typography>}
      {license && <Typography variant="h6" className={Style.detail}>License: {license}</Typography>}
    </Box>
  </Box>
  );
}

export default AdditionalOptionTooltip;
