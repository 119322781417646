import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Dialog, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CarDetailsAPI from "api/resources/carDetails";
import LeadFormAPI from "api/resources/leadForm";
import FormSuccess from "assets/leads/form_success.svg";
import SelectDropdown from "components/FormInputs/Select/Index";
import TextInput from "components/FormInputs/TextInput/Index";
import InnerLoader from "components/Loading/InnerLoader";
import { CustomButton } from "components/Ui/Button/Button";
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import { httpService } from "helper/httpService";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { getCarMakesRequest, getCarModelsRequest, getCarYearsRequest } from "redux/reducers/carDetailsReducer";
import { validateData } from "utils/validation";
import * as Yup from 'yup';
import Style from "./../CreateLeadForm/CreateLeadForm.module.scss";
import IconAutomotive from './../CreateLeadForm/IconAutomotive';
import IconCommercial from "./../CreateLeadForm/IconCommercial";
import IconOther from "./../CreateLeadForm/IconOther";
import IconResidential from "./../CreateLeadForm/IconResidential";
const CustomTextInput = require('components/Ui/TextInput/TextInput').default
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const windowTintingType = [
  'Full Car (Exc Windshield)',
  'Rear Half (B-Pillar Back)',
  '2x Front Windows',
  'Others'
]

const noOfWindow = [
  '1-4',
  '5-10',
  '11-15',
  '16+'
]

const Step1Schema = Yup.object({
  category: Yup.string().required("Please select category").trim(),
  service: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("Please select service").trim(),
    otherwise: (schema) => schema.notRequired(),
  })
});

const Step2Schema = Yup.object({
  make: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("Please select make").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  model: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("Please select make").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  type: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("Please select make").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  year: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("Please select make").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  tinting_type: Yup.string().trim().when('category', {
    is: 'Automotive',
    then: (schema) => schema.required("AddedPlease select tinting Type").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  film_removal: Yup.string().required("Please select Old films needs to be remove?").trim(),
  no_of_window: Yup.string().trim().when('category', {
    is: (value: any) => value !== 'Automotive',
    then: (schema) => schema.required("Please select tinting Type").trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const Step3Schema = Yup.object({
  first_name: Yup.string().required("First Name is Required").trim(),
  last_name: Yup.string().required("Last Name is Required").trim(),
  city: Yup.string().required("City is Required").trim(),
  email: Yup.string().trim().required("Email is Required").email('Please enter valid email address'),
  phone: Yup.string().trim().required("Phone number is Required").matches(phoneRegExp, { message: 'Phone number is not valid', excludeEmptyString: true }).nullable(),
});

function FormPreview({ publicForm, leadFormDetails, previewAutomotiveServices }: any) {
  const initFormData = {
    category: '',
    services: '',
    make: '',
    model: '',
    type: '',
    year: '',
    film_removal: '',
    tinting_type: '',
    no_of_window: '',
    description: '',
    first_name: '',
    last_name: '',
    company: '',
    city: '',
    email: '',
    phone: '',
    public_id: leadFormDetails.public_id,
    service_type: ''
  }
  
  const [formPreviewData, setFormPreviewData] = useState<any>(initFormData)
  const [formPreviewActiveStep, setFormPreviewActiveStep] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<any>({})
  const [progress, setProgress] = useState(100 / 3);

  const { makes, models, years, carTypes } = useAppSelector((state: any) => state.carDetails);
  useEffect(() => {
    httpService(() => CarDetailsAPI.listMakes(), getCarMakesRequest, false);
  }, []);

  const nextStep = async () => {
    if (publicForm === true) {
      const isValidated = await validateForm(formPreviewActiveStep, formPreviewData)
      if (isValidated === false) {
        return false
      }
    }
    if (formPreviewActiveStep < 2) {
      setProgress(progress + (100 / 3))
      setFormPreviewActiveStep(formPreviewActiveStep + 1)
    }
    if (formPreviewActiveStep === 2 && publicForm === true) {
      setIsLoading(true)
      try {
        if (formPreviewData.category === 'Automotive') {
          const data = {
            category: formPreviewData.category,
            make: formPreviewData.make,
            model: formPreviewData.model,
            type: formPreviewData.type,
            year: formPreviewData.year,
            film_removal: formPreviewData.film_removal,
            tinting_type: formPreviewData.tinting_type,
            description: formPreviewData.description,
            first_name: formPreviewData.first_name,
            last_name: formPreviewData.last_name,
            company: formPreviewData.company,
            city: formPreviewData.city,
            email: formPreviewData.email,
            phone: formPreviewData.phone,
            public_id: leadFormDetails.public_id,
            service_type: formPreviewData.service_type,
            service: 'Automotive Window Tinting'
          }
          await LeadFormAPI.submitAutomotiveForm(data)
        }

        if (formPreviewData.category === 'Residential' || formPreviewData.category === 'Commercial' || formPreviewData.category === 'Other') {
          const data = {
            category: formPreviewData.category,
            film_removal: formPreviewData.film_removal,
            no_of_window: formPreviewData.no_of_window,
            description: formPreviewData.description,
            first_name: formPreviewData.first_name,
            last_name: formPreviewData.last_name,
            company: formPreviewData.company,
            city: formPreviewData.city,
            email: formPreviewData.email,
            phone: formPreviewData.phone,
            service_type: 'Architectural Window Tinting',
            public_id: leadFormDetails.public_id,
          }
          await LeadFormAPI.submitArchitecturalForm(data)
        }
        setFormPreviewData(initFormData)
        setFormPreviewActiveStep(0)
        setProgress(100 / 3)
        setIsComplete(true)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        toast.error("Error in data submit.");
      }
    }
  }

  const prevStep = () => {
    if (formPreviewActiveStep > 0) {
      setProgress(progress - (100 / 3))
      setFormPreviewActiveStep(formPreviewActiveStep - 1)
    }
  }

  const handleFormPreviewData = (name: string, value: string) => {
    setFormPreviewData({
      ...formPreviewData,
      [name]: value
    })
  }

  const getAdditionalValue = (name: string, defaultValue: string = '') => {
    const setting = leadFormDetails.additional_form_settings?.find((setting: any) => setting.key === name)
    return setting?.value || defaultValue
  }

  const validateForm = async (step: any, data: any) => {
    setValidationError({})
    switch (step) {
      case 0: {
        const errors = await validateData(Step1Schema, data)
        if (errors?.isValidate === false) {
          setValidationError(errors.errors)
          return false
        }
        return true
      }
      case 1: {
        const errors = await validateData(Step2Schema, data)
        if (errors?.isValidate === false) {
          setValidationError(errors.errors)
          return false
        }
        return true
      }
      case 2: {
        const errors = await validateData(Step3Schema, data)
        if (errors?.isValidate === false) {
          setValidationError(errors.errors)
          return false
        }
        return true
      }
      default:
        return true;
    }
  };

  const dispatch = useDispatch();
  const getCarModels = async (makeID: number, getYear = false) => {
    try {
      const data = await CarDetailsAPI.listModels(makeID);
      dispatch(getCarModelsRequest(data.data))
      if (getYear === true && formPreviewData.model) {
        const model = data.data.find((item: any) => item.model === formPreviewData.model);
        getCarYears(model?.id);
      }
    } catch (err) {
      dispatch(getCarModelsRequest([]))
      console.log(err);
    }
  };

  const getCarYears = async (modelId: number) => {
    try {
      const data = await CarDetailsAPI.listYears(modelId);
      const years = data.data.sort((a: any, b: any) => b.year - a.year)
      dispatch(getCarYearsRequest(years))
    } catch (err) {
      dispatch(getCarYearsRequest([]))
      console.log(err);
    }
  };

  const handleChange = (name: string, value: any) => {
    let formData = { ...formPreviewData, [name]: value }

    if (name === 'make' && value) {
      const make = makes.find((item: any) => item.make === value);
      getCarModels(make?.id);
      formData = { ...formData, model: '', year: '' }
    }
    if (name === 'model') {
      const model = models.find((item: any) => item.model === value);
      getCarYears(model?.id);
      formData = { ...formData, year: '' }
    }

    setFormPreviewData({ ...formData })
  };


  return <Box className={Style.formRow} mt={2.5} sx={{ backgroundColor: `${getAdditionalValue('background_color', '#FFFFFF')} !important` }}>
    {isLoading && <InnerLoader height="auto" />}
    {publicForm === false && <Typography variant="title" className={Style.sectionTitle}>Form Preview</Typography>}
    <Box position={'relative'} overflow={'hidden'}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          marginBottom: '40px',
          borderRadius: '6px',
          height: '33px',
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#F2F2F2',
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: getAdditionalValue('highlight_color', '#3E8BFF'),
            borderRadius: '6px',
          }
        }} />
      <Typography variant="title" className={Style.progressValue} sx={{ left: `${progress - 6}%` }}>{parseInt(progress.toString())}%</Typography>
    </Box>
    {/* Automotive Step: 1 */}
    {formPreviewActiveStep === 0 && <>
      <Typography variant="title" component={'h4'} className={Style.formTitle}>Choose Your Quote Category</Typography>
      <Box display={'flex'} gap={'90px'} alignItems={'baseline'} justifyContent={'space-around'} mb={'40px'}>
        {leadFormDetails.for_automotive === true &&
          <Box onClick={() => handleFormPreviewData('category', 'Automotive')} display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <IconAutomotive color={formPreviewData.category === 'Automotive' ? "#000000" : "#818181"} />
            <Typography variant="title" className={Style.iconText} color={formPreviewData.category === 'Automotive' ? "#000000" : "#818181"}>Automotive</Typography>
          </Box>}
        {leadFormDetails.for_architectural === true &&
          <Box onClick={() => handleFormPreviewData('category', 'Residential')} display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <IconResidential color={formPreviewData.category === 'Residential' ? "#000000" : "#818181"} />
            <Typography variant="title" className={Style.iconText} color={formPreviewData.category === 'Residential' ? "#000000" : "#818181"}>Residential</Typography>
          </Box>
        }
        {leadFormDetails.for_commercial === true &&
          <Box onClick={() => handleFormPreviewData('category', 'Commercial')} display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <IconCommercial color={formPreviewData.category === 'Commercial' ? "#000000" : "#818181"} />
            <Typography variant="title" className={Style.iconText} color={formPreviewData.category === 'Commercial' ? "#000000" : "#818181"}>Commercial</Typography>
          </Box>
        }
        {leadFormDetails.for_other === true &&
          <Box onClick={() => handleFormPreviewData('category', 'Other')} display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <IconOther color={formPreviewData.category === 'Other' ? "#000000" : "#818181"} />
            <Typography variant="title" className={Style.iconText} color={formPreviewData.category === 'Other' ? "#000000" : "#818181"}>Other</Typography>
          </Box>
        }
      </Box>
      <ErrorMessage error={_.get(validationError, ['category'], '')} />
      {formPreviewData.category === 'Automotive' &&
        <Grid container columnSpacing={2.5} rowSpacing={2.5} mb={2.5}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <SelectDropdown
              name="service"
              label="Automotive Service"
              value={formPreviewData.service}
              options={previewAutomotiveServices}
              onChange={(e: any) => handleFormPreviewData('service', e.target.value)}
            />
            <ErrorMessage error={_.get(validationError, ['service'], '')} />
          </Grid>
        </Grid>}
    </>}
    {/* Automotive Step: 2 */}
    {formPreviewActiveStep === 1 && <>
      {formPreviewData.category === 'Automotive' && <>
        <Grid container columnSpacing={2.5} rowSpacing={2.5} mb={2.5}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='title2' component='label' className={Style.inputLabel}>Vehicle Make</Typography>
            <Autocomplete
              options={makes}
              getOptionLabel={(option) => option?.make}
              onChange={(_, value: any) => handleChange('make', value?.make)}
              value={{ make: formPreviewData?.make || '' }}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.make === value.make
              }}
              renderInput={(params) => (
                <CustomTextInput
                  {...params}
                  size="small"
                  margin="dense"
                />
              )}
              fullWidth
            />
            <ErrorMessage error={_.get(validationError, ['make'], '')} />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='title2' component='label' className={Style.inputLabel}>Vehicle Model</Typography>
            <Autocomplete
              options={models}
              getOptionLabel={(option) => option?.model}
              onChange={(_, value: any) => handleChange('model', value?.model)}
              value={{ model: formPreviewData?.model || '' }}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.model === value.model
              }}
              renderInput={(params) => (
                <CustomTextInput
                  {...params}
                  size="small"
                  margin="dense"
                />
              )}
              fullWidth
              disabled={!formPreviewData.make}
            />
            <ErrorMessage error={_.get(validationError, ['model'], '')} />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='title2' component='label' className={Style.inputLabel}>Vehicle Type</Typography>
            <Autocomplete
              options={carTypes}
              value={formPreviewData?.type || ''}
              onChange={(_, value: any) => handleChange('type', value)}
              renderInput={(params) => (
                <CustomTextInput
                  {...params}
                  size="small"
                  margin="dense"
                />
              )}
              fullWidth
            />
            <ErrorMessage error={_.get(validationError, ['type'], '')} />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='title2' component='label' className={Style.inputLabel}>Vehicle Year</Typography>
            <Autocomplete
              options={years}
              getOptionLabel={(option) => option?.year}
              onChange={(_, value: any) => handleChange('year', value?.year)}
              value={{ year: formPreviewData?.year || '' }}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.year === value.year
              }}
              renderInput={(params) => (
                <CustomTextInput
                  {...params}
                  size="small"
                  margin="dense"
                />
              )}
              fullWidth
              disabled={!formPreviewData.make}
            />
            <ErrorMessage error={_.get(validationError, ['year'], '')} />
          </Grid>
        </Grid>
        <Box mb={2.5}>
          <Typography variant='title2' component='label' className={Style.inputLabel}>What area of your car do you need tinting</Typography>
          <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
            {windowTintingType.map((tintingType: any, index: number) => (
              <FormControlLabel key={`windowTinting_${index}`} control={
                <BpCheckbox
                  checked={formPreviewData.tinting_type === tintingType}
                  onChange={(e: any) => handleFormPreviewData('tinting_type', e.target.value)}
                  name="tinting_type"
                  value={tintingType}
                />
              } label={tintingType} />
            ))}
            <ErrorMessage error={_.get(validationError, ['tinting_type'], '')} />
          </Box>
        </Box>
      </>}

      <Box mb={2.5}>
        <Typography variant='title2' component='label' className={Style.inputLabel}>Is there film that needs to be removed?</Typography>
        <Box>
          <FormControlLabel control={
            <BpCheckbox
              checked={formPreviewData.film_removal === 'Yes'}
              name="film_removal"
              onChange={(e: any) => handleFormPreviewData('film_removal', e.target.value)}
              value={'Yes'}
            />
          } label="Yes" />
          <FormControlLabel control={
            <BpCheckbox
              checked={formPreviewData.film_removal === 'No'}
              onChange={(e: any) => handleFormPreviewData('film_removal', e.target.value)}
              name="film_removal"
              value={'No'}
            />
          } label="No" />
        </Box>
        <ErrorMessage error={_.get(validationError, ['film_removal'], '')} />
      </Box>
      {formPreviewData.category !== 'Automotive' &&
        <Box mb={2.5}>
          <Typography variant='title2' component='label' className={Style.inputLabel}>How many windows?</Typography>
          <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
            {noOfWindow.map((window: any, index: number) => (
              <FormControlLabel
                key={`no_of_window_${index}`}
                control={<Radio
                  name="no_of_window"
                  onChange={(e: any) => handleFormPreviewData('no_of_window', e.target.value)}
                  checked={formPreviewData.no_of_window === window}
                  value={window}
                  sx={{
                    color: '#000',
                    '&.Mui-checked': {
                      color: '#58bcff',
                    },
                  }}
                />}
                label={window}
                sx={{ '& .MuiButtonBase-root': { padding: '0 5px 0 0' } }}
              />
            ))}
          </Box>
          <ErrorMessage error={_.get(validationError, ['no_of_window'], '')} />
        </Box>
      }
      <Box mb={2.5}>
        <TextInput
          name="description"
          label="Description of what you require"
          value={formPreviewData.description}
          onChange={(e: any) => handleFormPreviewData('description', e.target.value)}
          placeholder="Add additional Comments"
          multiline
          rows={5}
        />
      </Box>
    </>}
    {/* Automotive/Residential/Commercial/Other  Step: 3 */}
    {formPreviewActiveStep === 2 && <>
      <Grid container columnSpacing={2.5} rowSpacing={2.5} mb={2.5}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="first_name"
            label="First Name"
            value={formPreviewData.first_name}
            onChange={(e: any) => handleFormPreviewData('first_name', e.target.value)}
            placeholder="First Name"
          />
          <ErrorMessage error={_.get(validationError, ['first_name'], '')} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="last_name"
            label="Last Name"
            value={formPreviewData.last_name}
            onChange={(e: any) => handleFormPreviewData('last_name', e.target.value)}
            placeholder="Last Name"
          />
          <ErrorMessage error={_.get(validationError, ['last_name'], '')} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="company"
            label="Company Name"
            value={formPreviewData.company}
            onChange={(e: any) => handleFormPreviewData('company', e.target.value)}
            placeholder="Company Name"
          />
          <ErrorMessage error={_.get(validationError, ['company'], '')} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="city"
            label="City"
            value={formPreviewData.city}
            onChange={(e: any) => handleFormPreviewData('city', e.target.value)}
            placeholder="City"
          />
          <ErrorMessage error={_.get(validationError, ['city'], '')} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="email"
            label="Email"
            value={formPreviewData.email}
            onChange={(e: any) => handleFormPreviewData('email', e.target.value)}
            placeholder="Email"
          />
          <ErrorMessage error={_.get(validationError, ['email'], '')} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextInput
            name="phone"
            label="Phone"
            value={formPreviewData.phone}
            onChange={(e: any) => handleFormPreviewData('phone', e.target.value)}
            placeholder="Phone"
          />
          <ErrorMessage error={_.get(validationError, ['phone'], '')} />
        </Grid>
      </Grid>
    </>}
    <Box className={Style.buttonArea}>
      {formPreviewActiveStep > 0 ?
        <CustomButton
          sx={{
            background: '#000',
            '&:hover': {
              background: '#000',
            },
            paddingLeft: '20px !important'
          }}
          onClick={prevStep}><BiChevronLeft color="#FFF" size={25} /> Previous</CustomButton> : <Box></Box>
      }
      <CustomButton sx={{
        background: getAdditionalValue('highlight_color', '#3E8BFF'),
        '&:hover': {
          background: getAdditionalValue('highlight_color', '#3E8BFF'),
        },
        paddingRight: formPreviewActiveStep === 2 ? '36px !important' : '20px !important'
      }} onClick={nextStep}>
        {formPreviewActiveStep === 2 ? 'Submit' : <>Next <BiChevronRight color="#FFF" size={25} /></>}
      </CustomButton>
    </Box>
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isComplete}
      onClose={() => setIsComplete(false)}
      disableEnforceFocus
      scroll={"paper"}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          width: '560px',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0 84px',
          textAlign: 'center',
          position: 'relative'
        }
      }}
    >
      <CloseIcon
        onClick={() => setIsComplete(false)}
        fill="#A4A4A4"
        sx={{ position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }}
      />
      <Box sx={{
        height: '70px',
        overflow: 'hidden'
      }}>
        <img src={FormSuccess} alt="Success" />
      </Box>
      <Typography
        variant="heading2"
        component={'h2'}
        mb={'16px'}
        mt={'15px'}
        color={'#222222'}
      >Your Quote submitted successfully</Typography>
      <Typography
        variant="heading6"
        component={'h6'}
        fontSize={'500'}
        color={'#222222'}
      >Your quote submission has been received, we will be in touch style.</Typography>
    </Dialog>
  </Box>
}


export default FormPreview;
