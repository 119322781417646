import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import style from "./PageHeader.module.scss";
import SettingsIcon from "assets/icon/settings.svg";

function PageHeader() {
  const placeName = useAppSelector((state) => state.ui.placeName);
  const isArrow = useAppSelector((state) => state.ui.isArrow);
  const navigateLink: any = useAppSelector((state) => state.ui.navigate);
  const settings: any = useAppSelector((state) => state.ui.settings);
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (navigateLink !== '') {
      navigate(navigateLink)
    } else {
      navigate(-1)
    }
  }

  return (
    <Box
      className={style.pageHeader}
      sx={{ gridTemplateColumns: media ? "auto" : "1fr 1fr 1fr" }}
    >
      <Box className={style.flex}>
        {isArrow && (
          <MdArrowBackIos
            color="#fff"
            size={15}
            className="cursor"
            onClick={handleOnClick}
          />
        )}
        <Typography
          color="white"
          variant={'heading1'}
          component={'h1'}
          textTransform={"capitalize"}
          sx={{ cursor: navigateLink === '' ? 'default' : 'pointer' }}
          onClick={handleOnClick}
        >{placeName}</Typography>
      </Box>
      {!media && (
        <>
          <Box display={"flex"} justifyContent={"center"} alignItems={'center'}>
            <img
              src="/assets/images/logo/logo.png"
              alt="logo"
              style={{ width: "148.5px", height: "33px", flexShrink: 0 }}
            />
          </Box>
        </>
      )}
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={'flex-end'}>
        {settings && <img src={SettingsIcon} alt="settings" onClick={() => navigate(settings)} className="cursor" />}
      </Box>
    </Box>
  );
}

export default PageHeader;
