/* eslint-disable import/prefer-default-export */
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { themeColor } from "theme/color";

export const handleColor = (label: any) => {
  switch (label) {
    case "primary":
      return { color: themeColor.white, background: themeColor.primary };
    case "success":
      return { color: themeColor.white, background: themeColor.success };
    case "dark":
      return { color: themeColor.white, background: themeColor.black };
    case "New":
      return { color: themeColor.white, background: themeColor.new };
    case "Quoted":
      return { color: themeColor.white, background: themeColor.quoted };
    case "Sent":
      return { color: themeColor.white, background: themeColor.sent };
    case "Opened":
      return { color: themeColor.white, background: themeColor.opened };
    case "Dark":
      return { color: themeColor.white, background: themeColor.dark };
    case "Consult Scheduled":
      return {
        color: themeColor.white,
        background: themeColor.consultScheduled,
      };
    case "Approved":
      return { color: themeColor.white, background: themeColor.approved };
    case "In-Progress":
      return { color: themeColor.white, background: themeColor.progress };
    case "Scheduled":
      return { color: themeColor.white, background: themeColor.scheduled };
    case "Completed":
      return { color: themeColor.white, background: themeColor.completed };
    case "Cancelled":
      return { color: themeColor.white, background: themeColor.cancelled };
    case "Rejected":
      return { color: themeColor.white, background: themeColor.cancelled };
    case "Archived":
      return { color: themeColor.white, background: themeColor.cancelled };
    case "Comeback":
      return { color: themeColor.white, background: themeColor.comeback };
    case "No Show":
      return { color: themeColor.white, background: themeColor.noShow };
    case "Upsell":
      return { color: themeColor.white, background: themeColor.upsell };
    case "Gray":
      return { color: themeColor.white, background: themeColor.tagGray };
    case "Unpaid":
      return { color: themeColor.white, background: themeColor.unPaid };
    case "Paid":
      return { color: themeColor.white, background: themeColor.paid };
    case "Active":
      return { color: themeColor.white, background: themeColor.active };
    case "InActive":
      return { color: themeColor.white, background: themeColor.inActive };
    case "SeaGreen":
      return { color: themeColor.white, background: themeColor.seaGreen };
    case "Overdue":
      return { color: themeColor.white, background: themeColor.overdue };
    case "Finished":
      return { color: themeColor.white, background: themeColor.completed };
    case "btn-modify":
      return { color: themeColor.white, background: themeColor.modifyBtn };
    case "btn-red":
      return { color: themeColor.white, background: themeColor.redBtn };
    case "btn-green":
      return { color: themeColor.white, background: themeColor.completeBtn };
    case "btn-orange":
      return { color: themeColor.white, background: themeColor.orangeBtn };
    case "btn-dark":
      return { color: themeColor.white, background: themeColor.darkBtn };
    case "btn-link": 
      return { color: themeColor.linkColor, background: 'transparent' };
    case "Draft Quote":
      return { color: themeColor.white, background: themeColor.draft };
    case "orange":
      return { color: themeColor.white, background: themeColor.progress };
    case "green":
        return { color: themeColor.white, background: themeColor.complete };
    case "gray":
      return { color: themeColor.white, background: themeColor.tagGray };
    case "purple":
      return { color: themeColor.white, background: themeColor.purple };
    case "Voided":
      return { color: themeColor.white, background: themeColor.comeback };
    default:
      return {
        color: themeColor.black,
        background: themeColor.default,
      };
  }
};

export const Tag = styled(Chip)(({ theme, className }) => ({
  fontFamily: "'Inter', sans-serif",
  textEdge: "cap",
  fontSize: "12px",
  borderRadius: "5px",
  lineHeight: "25px",
  height: 25,
  fontWeight: 600,
  ...handleColor(className),
}));

export const DefaultTag = styled(Chip)(({ theme, className }) => ({
  color: theme.palette.common.black,
  fontFamily: "'Inter', sans-serif",
  textEdge: "cap",
  fontSize: "12px",
  borderRadius: "5px",
  height: 25,
  lineHeight: "25px",
  background: themeColor.default,
}));

export const LiveLeadTag = styled(Chip)(({ theme, className }) => ({
  fontFamily: "'Inter', sans-serif",
  textEdge: "cap",
  fontSize: "11px",
  fontWeight: "600",
  lineHeight: "18px",
  height: 'auto',
  borderRadius: '4px',
  padding: '0',
  ...handleColor(className),
}));

export const BehaviorTag = styled(Chip)(({ theme }: any) => ({
  fontFamily: "'Inter', sans-serif",
  textEdge: "cap",
  fontSize: "11px",
  fontWeight: "600",
  lineHeight: "18px",
  height: 'auto',
  borderRadius: '4px',
  padding: '0',
  color: '#FFF'
}));
