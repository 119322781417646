import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  MenuItem,
  Typography
} from '@mui/material';
import SquareIcon from 'assets/icon/add_square.svg';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { updateQuoteJob } from 'redux/reducers/quickQuoteReducer';
import TextInput from "../../../components/Ui/TextInput/TextInput";
import styles from "./Application.module.scss";
import ApplicationDetail from './ApplicationDetail';
import { useNavigate } from "react-router-dom";

interface ApplicationDetailsProps {
  job: any,
  index: number,
  validationError?: any,
  messageForCustomer?: any,
  showEditLink?: any
  onEditJob?: any
  fromQuickQuote?: any
  project_id?: any
  contact_id?: any
}

function ApplicationDetails({ job, index, validationError, messageForCustomer, showEditLink, onEditJob, fromQuickQuote, project_id, contact_id }: ApplicationDetailsProps) {
  const navigate = useNavigate();
  const { make, model, car_type, year, services } = job;
  const dispatch = useDispatch();

  const handleUpdate = (name: string, value: any) => {
    dispatch(updateQuoteJob({ index, name, value }))
  };

  const handleRemoveService = (index: number) => {
    const updatedServices = services.filter((_: any, i: number) => i !== index);
    handleUpdate('services', updatedServices);
  };

  const handleAddService = (type: any) => {
    const updatedServices = [...services, {
      type, multi_price_option: false, price: 0, applications: [], service_title: ''
    }];
    handleUpdate('services', updatedServices);
  };

  const handleUpdateService = (serviceIndex: number, name: string, value: any) => {
    const updatedServices = services.map((service: any, i: number) => (i === serviceIndex ? { ...service, [name]: value } : service)).map((service: any) => {
      return {
        ...service,
        total_price_with_discount: _.get(service, 'discount', 0) === true ? _.get(service, 'discount_price', 0) : _.get(service, 'price', 0),
        total_price_without_discount: _.get(service, 'price', 0)
      }
    })
    handleUpdate('services', updatedServices)
  };

  const handleEditProject = () => {
    if (fromQuickQuote === false) {
      navigate(`/leads/contacts/new-project?id=${contact_id}&project_id=${project_id}`)
    } else {
      onEditJob()
    }
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.section}>
        <div className={styles.sectionInfo}>
          <Typography variant="subTitle1">({year}) {make} {model}</Typography>
          <Typography variant='subtitle2'>-</Typography>
          <Typography variant='subtitle2'>{car_type}</Typography>
          {showEditLink === true && <Typography variant='linkText' className='cursor' ml={'10px'} onClick={handleEditProject}>Edit</Typography>}
        </div>

        <div className={styles.inputWrapper}>
          {services.map((service: any, i: number) =>
            <div key={i} className={styles.flexWrapper}>
              <div className={styles.serviceTag}>
                <Typography variant='subtitle2' fontWeight={600} color={'#000'}>
                  {service.type === 'Other' ? 'Other Service' : service.type}
                </Typography>
                <IconButton onClick={() => handleRemoveService(i)} className={styles.CloseIcon}>
                  <CloseIcon />
                </IconButton>
              </div>
              {serviceTypes.length !== services.length &&
                <img src={SquareIcon} alt=''
                  className={styles.cursorPointer}
                  onClick={() => handleAddService((service.type === 'Automotive Window Tinting') ? 'Other' : 'Automotive Window Tinting')} />
              }
            </div>
          )}

          {services.length === 0 && (
            <TextInput
              onChange={(e) => handleAddService(e.target.value)}
              name='service_type'
              label={'Add Service'}
              size='small'
              value={''}
              defaultValue={''}
              margin='dense'
              select
              className={styles.serviceField}
              fullWidth
            >
              {
                serviceTypes.map((type, i) => (
                  <MenuItem
                    value={type.value}
                    key={`service_type_${i}`}
                    disabled={services?.some((service: any) => service.type === type.value)}
                  >
                    {type.name}
                  </MenuItem>
                ))}
            </TextInput>
          )}
        </div>
        <ErrorMessage error={_.get(validationError, ['jobs[0].services'], '')} />
      </Box>
      {
        services.map((service: any, i: number) => <ApplicationDetail
          key={`service_${i}`}
          index={i}
          handleUpdateService={handleUpdateService}
          handleUpdateJob={handleUpdate}
          job={job}
          validationError={validationError}
          messageForCustomer={messageForCustomer}
        />)
      }
    </Box>
  );
}

export default ApplicationDetails;

const serviceTypes = [
  {
    name: 'Automotive Window Tinting',
    value: 'Automotive Window Tinting'
  },
  {
    name: 'Other Service',
    value: 'Other'
  }
];
