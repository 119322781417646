import { Avatar, Badge, Box, Menu, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { themeColor } from "theme/color";
import styles from "./Header.module.scss";
import { getSafeValue } from "utils";

function Notifications({ data, clearNotifications, unreadNotificationCount }: any) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    clearNotifications()
  };

  const handleNotificationClick = (data: any) => {
    const type = getSafeValue(data, 'type', '')
    const project_id = getSafeValue(data, 'data.project_id', 0)
    const contact_id = getSafeValue(data, 'data.contact_id', 0)

    switch (type) {
      case 'App\\Notifications\\AppointmentInOneHourNotification':
        console.log('Handling Appointment in One Hour Notification');
        break;
      case 'App\\Notifications\\AppointmentInThirtyMinutesNotification':
        console.log('Handling Appointment in Thirty Minutes Notification');
        break;
      case 'App\\Notifications\\BookingCancelledOnYourCalendarNotification':
        console.log('Handling Booking Cancelled on Your Calendar Notification');
        break;
      case 'App\\Notifications\\BookingChangedOnYourCalendarNotification':
        console.log('Handling Booking Changed on Your Calendar Notification');
        break;
      case 'App\\Notifications\\BookingScheduledOnYourCalendarNotification':
        console.log('Handling Booking Scheduled on Your Calendar Notification');
        break;
      case 'App\\Notifications\\CustomerReviewNotification':
        navigate(`/leads/contacts/reviews?id=${contact_id}`)
        break;
      case 'App\\Notifications\\CustomerYouIntroducedScheduledNotification':
        console.log('Handling Customer You Introduced Scheduled Notification');
        break;
      case 'App\\Notifications\\InvoicePaidNotification':
        navigate(`/leads/contacts/invoices/view?id=${contact_id}&project_id=${project_id}`)
        break;
      case 'App\\Notifications\\LeadApprovedNotification':
        navigate(`/leads/contacts/projects/project/${project_id}/service?id=${contact_id}`)
        break;
      case 'App\\Notifications\\LeadExpiredNotification':
        navigate(`/leads/contacts/projects/project/${project_id}/service?id=${contact_id}`)
        break;
      case 'App\\Notifications\\LeadRejectedNotification':
        navigate(`/leads/contacts/projects/project/${project_id}/service?id=${contact_id}`)
        break;
      case 'App\\Notifications\\NewLeadFormSubmissionNotification':
        navigate(`/leads/contacts/new-project-application?id=${contact_id}&project_id=${project_id}`)
        break;
      case 'App\\Notifications\\NewLeadNotification':
        navigate(`/leads/contacts/overview?id=${contact_id}`)
        break;
      case 'App\\Notifications\\PaymentTakenNotification':
        navigate(`/leads/contacts/invoices/view?id=${contact_id}&project_id=${project_id}`)
        break;
      case 'App\\Notifications\\ProposalViewedNotification':
        navigate(`/leads/contacts/projects/project/${project_id}/service?id=${contact_id}`)
        break;
      case 'App\\Notifications\\ReviewSafeguardNotification':
        navigate(`/leads/contacts/reviews?id=${contact_id}`)
        break;
      case 'App\\Notifications\\ReviewViewedNotification':
        navigate(`/leads/contacts/reviews?id=${contact_id}`)
        break;
      case 'App\\Notifications\\YourAppointmentInOneHourNotification':
        console.log('Handling Your Appointment in One Hour Notification');
        break;
      case 'App\\Notifications\\BookingScheduledNotification':
        navigate(`/leads/contacts/projects/project/${project_id}/service?id=${contact_id}`)
        break;
      default:
        console.log('Unknown Notification Type');
    }
  }

  return (
    <>
      <Badge
        color="error"
        overlap="circular"
        badgeContent={unreadNotificationCount}
        sx={{
          "& .MuiBadge-badge": { height: 17, minWidth: 17, fontSize: "11px" },
        }}
      >
        <Avatar onClick={handleClick} sx={{ bgcolor: themeColor.gray }}>
          <img
            src="/assets/images/icons/notification.svg"
            width={25}
            alt="menu icon"
          />
        </Avatar>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: '432px',
          },
          sx: {
            '& ul': {
              background: '#fff',
              paddingRight: '10px !important'
            },
            borderRadius: "8px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 16,
              height: 16,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box gap={1} className={styles.flexCenter} justifyContent={'space-between'} sx={{ padding: '12px 19px', marginRight: '12px', borderBottom: '1px solid #F6F6F6' }}>
          <Box gap={1} className={styles.flexCenter}>
            <img
              src="/assets/images/icons/notification.svg"
              alt="notification bell"
              style={{ filter: "brightness(0%)" }}
            />
            <Typography variant="title1" color="black" fontWeight={500}>
              Notifications {unreadNotificationCount > 0 ? `(${unreadNotificationCount})` : ''}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.menuScroll}>
          {data.length > 0 ? (
            data.map((item: any, index: any) => (
              <Box
                className={styles.flexCenter}
                sx={{ cursor: "pointer", "&:hover": { background: "rgba(0, 0, 0, 0.04)" } }}
                justifyContent={"space-between"}
                key={index}
                p={'7px 19px'}
                borderBottom={`${data.length === index + 1 ? "0px" : "1px"} solid #F6F6F6`}
                onClick={() => handleNotificationClick(item)}
              >
                <Box className={styles.flexInner} gap={1.5}>
                  <Box textAlign="center">
                    <Typography fontWeight={700} variant="title3" fontSize={'15px'}>
                      {item.time}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="title2"
                      fontWeight={600}
                      fontSize={'15px'}
                      color="#000"
                      lineHeight={'138%'}
                      dangerouslySetInnerHTML={{ __html: item.data.message }}
                      className={styles.textOverflow}
                      title={item.data.message}
                    />
                    <Typography
                      variant="title4"
                      color={'#2E2E2E'}
                      className={styles.textOverflow}
                      fontSize={'11px'}
                      fontWeight={'500'}
                      dangerouslySetInnerHTML={{ __html: item.data['sub-message'] }}
                      title={item.data['sub-message']}
                    />
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box py={3}>
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="title2"
                display="block"
              >
                No Notification
              </Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default Notifications;
