import { Box, useMediaQuery, useTheme } from "@mui/material";
import InnerLoader from "components/Loading/InnerLoader";
import PageHeader from "components/PageHeader/PageHeader";
import { useRef } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { themeColor } from "theme/color";
import { constant } from "utils/constant";
import Header from "../components/Header/Header";
import SideMenu from "../components/SideMenu/SideMenu";

const Dashboard = ({ children }: any) => {
  const ref: any = useRef(null);
  const isOpen = useAppSelector((state) => state.ui.isOpen);
  const isLoading = useAppSelector((state) => state.loading.isInnerLoading);
  const bgColor = useAppSelector((state) => state.ui.bgColor);
  const changeMainContainerPadding = useAppSelector((state) => state.ui.mainContainerPadding);
  
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("lg"));
  const getWidth = () => {
    if (media) {
      return 0;
    }
    if (isOpen) {
      return constant.sideMenu + constant.menuWidth;
    }
    return constant.sideMenu;
  };

  return (
    <>
      <Header />
      <SideMenu />
      <Box
        ref={ref}
        sx={{ transition: " 0.6s ease-in-out" }}
        paddingTop={`${constant.height}px`}
        paddingLeft={`${getWidth()}px`}
        bgcolor={themeColor.bodyBg}
        className="bodyContainer"
      >
        <Box className="main">
          <PageHeader />
          <Box className="mainContent" bgcolor={bgColor} padding={changeMainContainerPadding}>
            {isLoading && <InnerLoader />}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
