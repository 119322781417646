import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSafeValue } from "utils";

const initialState: any = {
  data: {
    id: '',
    contact: {
      company: '',
      discoveries: [],
      email: '',
      phone_number: '',
      phone_number_title: '',
      phone_number_receive_sms: false,
      phone_number_2: '',
      phone_number2_title: '',
      phone_number2_receive_sms: false,
      address: {},
      receive_email: true,
      is_business: false,
      mobile_install: false,
      full_address: false,
    },
    jobs: [{
      type: '',
      make: '',
      model: '',
      car_type: '',
      year: '',
      color: '',
      vin: '',
      license: '',
      address: {},
      mobile_install: false,
      mobile_price: 0,
      services: [],
      price: 0,
      measurements: [],
      film_removal: [{}],
      film_options: [],
      total_sqft: 0,
      appointment: null,
    }],
    timeline_history: {},
    appointment: {},
    notes: []
  },
  loading: false,
  errors: null,
  success: false,
  completed_steps: { 0: false, 1: false, 2: false, 3: false, 4: false },
  active_step: 0,
  is_quick_quote_open: false,
  temp_job: [],
  schedule_job: false,
  project_id: 0,
  job_status: '',
  max_steps_completed: 0
};

export const quickQuoteReducer = createSlice({
  name: "quickQuote",
  initialState,
  reducers: {
    setQuickQuoteData: (state, action: PayloadAction<any>) => {
      state.data = action.payload
      state.project_id = getSafeValue(action, 'payload.jobs[0].id', 0)
      state.job_status = getSafeValue(action, 'payload.jobs[0].status', '')
    },
    createQuoteRequest: (state, action: PayloadAction<any>) => {
      const jobs = (state.temp_job.length > 0) ? state.temp_job : action.payload.quickQuote.jobs
      state.data = { ...action.payload.quickQuote, jobs: jobs };
      state.loading = false;
      state.errors = null;
      state.success = true;
      state.temp_job = [];
    },
    updateQuoteRequest: (state, action: PayloadAction<any>) => {
      state.data = action.payload.quickQuote;
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
    resetQuoteState: (state, action: PayloadAction<any>) => {
      state.data = initialState.data;
      state.loading = initialState.loading;
      state.errors = initialState.errors;
      state.success = initialState.success;
      state.completed_steps = initialState.completed_steps
      state.active_step = initialState.active_step
      state.is_quick_quote_open = action.payload
      state.temp_job = initialState.temp_job;
      state.project_id = initialState.project_id;
      state.job_status = initialState.job_status;
    },
    deleteRoomFromQuoteState: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    resetQuoteWithCustomerInfo: (state, action: PayloadAction<any>) => {
      state.data = { ...initialState.data, contact: action.payload }
    },
    updateQuoteJob: (state, action: PayloadAction<any>) => {
      const { index, name, value } = action.payload
      const updatedQuote: any = { ...state.data }
      updatedQuote.jobs[index][name] = value
      state.data = updatedQuote
    },
    setContact: (state, action: PayloadAction<any>) => {
      state.data.contact = action.payload
    },
    updateContact: (state, action: PayloadAction<any>) => {
      const { name, value } = action.payload
      const contact = state.data.contact
      const updatedContact = {
        ...contact,
        [name]: value
      }
      state.data.contact = updatedContact
    },
    setInitialJob: (state) => {
      state.data.jobs = initialState.data.jobs
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload
    },
    updateAppointment: (state, action: PayloadAction<any>) => {
      const { name, value } = action.payload
      const appointment = state.data.appointment
      const updatedAppointment = {
        ...appointment,
        [name]: value
      }
      state.data.appointment = updatedAppointment
    },
    updateCompletedSteps: (state, action: PayloadAction<any>) => {
      const step = action.payload
      state.completed_steps = {
        ...state.completed_steps,
        [step]: true
      }
    },
    toggleQuickQuotePopup: (state, action: PayloadAction<any>) => {
      state.is_quick_quote_open = action.payload
    },
    updateActiveStep: (state, action: PayloadAction<any>) => {
      state.active_step = action.payload
    },
    updateMaxStep: (state, action: PayloadAction<any>) => {
      state.max_steps_completed = action.payload
    },
    setTemporaryJobs: (state, action: PayloadAction<any>) => {
      state.temp_job = action.payload
    },
    resetScheduleJob: (state) => {
      state.schedule_job = false
    },
    setScheduleJob: (state) => {
      state.schedule_job = true
    },
    setAppointment: (state, action: PayloadAction<any>) => {
      state.data.appointment = action.payload
    }
  },
});

export const {
  createQuoteRequest,
  updateQuoteRequest,
  resetQuoteState,
  deleteRoomFromQuoteState,
  resetQuoteWithCustomerInfo,
  updateQuoteJob,
  setQuickQuoteData,
  setContact,
  updateContact,
  setInitialJob,
  setLoading,
  updateAppointment,
  updateCompletedSteps,
  toggleQuickQuotePopup,
  updateActiveStep,
  setTemporaryJobs,
  resetScheduleJob,
  setScheduleJob,
  setAppointment,
  updateMaxStep
} = quickQuoteReducer.actions;

export default quickQuoteReducer.reducer;
