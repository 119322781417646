/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Box, Dialog, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import UserAPI from "api/resources/user";
import InnerLoader from "components/Loading/InnerLoader";
import { PrimaryButton } from 'components/Ui/Button/Button';
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import { useEffect, useState } from "react";
import uuid4 from "uuid4";
import Style from "./ProjectAddress.module.scss";
import { getFullAddress, getSafeValue } from 'utils';
import ContactAPI from 'api/resources/contact';

const ProjectAddress = ({ onPopupClose, handleUpdateLocation, location, contact_id = '' }: any) => {
  const [scheduleAddress, setScheduleAddress] = useState<any>(location)
  const [storeLocation, setStoreLocations] = useState<any>([])
  const [isMobileVisit, setIsMobileVisit] = useState<boolean>(location?.isMobileVisit || false)
  const [sameAsContactAddress, setSameAsContactAddress] = useState<boolean>(location?.sameAsContactAddress || false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isMobileAddress, setIsMobileAddress] = useState<any>(false)

  useEffect(() => {
    getLocation()
  }, [])

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onPopupClose(false);
    }
  };

  const handleLocationChange = (event: any) => {
    setIsMobileAddress(true)
    const { name, value } = event.target
    setScheduleAddress({ ...scheduleAddress, [name]: value })
  }

  const handleAddressChange = async (name: any, value: any) => {
    setIsMobileAddress(true)
    if (name === 'state_province') {
      if (value === null) {
        setCities([])
      } else {
        await getCities(value)
      }
      setScheduleAddress({ ...scheduleAddress, [name]: value, city: '' })
    } else {
      setScheduleAddress({ ...scheduleAddress, [name]: value })
    }
  }

  const saveLocation = () => {
    const location = { ...scheduleAddress, isMobileVisit, sameAsContactAddress }
    handleUpdateLocation({ location, mobile_install: isMobileAddress })
    onPopupClose(false)
  }

  const getLocation = async () => {
    setIsLoading(true)
    const locations: any = await UserAPI.getBusinessLocations(contact_id)
    const allLocations = locations?.data.map((location: any) => {
      const { name } = location
      return {
        ...location,
        ...location.address,
        display_name: `(${name}) ${getFullAddress(location.address)}`
      }
    })
    setStoreLocations(allLocations)
    setIsLoading(false)
  }

  const _handleLocationChange = (event: any) => {
    if (event.target.value.name === 'Customer Address') {
      setIsMobileAddress(true)
    }
    setScheduleAddress(event.target.value)
  }

  const handleSameAsContactAddress = () => {
    if (sameAsContactAddress === true) {
      setIsMobileAddress(true)
    }
    setSameAsContactAddress((prev) => !prev)
  }

  useEffect(() => {
    getState()
  }, [])

  const [state, setState] = useState([])
  const getState = async () => {
    const allState = await ContactAPI.getState()
    setState(allState.data)
  }

  const [cities, setCities] = useState([])
  const getCities = async (state: any) => {
    const allState = await ContactAPI.getCity(state)
    setCities(allState.data)
  }

  return (
    <Dialog
      open={true}
      maxWidth='sm'
      disableEscapeKeyDown
      onClose={(event, reason) => handleClose(event, reason)}
      PaperProps={{ sx: { borderRadius: "10px", width: '558px' } }}
    >
      {isLoading && <InnerLoader height="auto" />}
      <Box className={Style.model}>
        <div className={Style.modelHeader}>
          <Typography className={Style.modelTitle} variant="title">Project Location</Typography>
          <CloseIcon className={Style.modelCloseIcon} onClick={handleClose} />
        </div>
        <div className={Style.modelBody}>
          <div className={Style.event}>
            <Grid item xs={12}>
              <FormControlLabel
                className={Style.checkboxLabel}
                onChange={() => setIsMobileVisit((prev) => !prev)}
                control={<BpCheckbox name="is_mobile_visit" checked={isMobileVisit} />}
                label="Add install address"
              />
            </Grid>

            {isMobileVisit === false ?
              <Select
                className={Style.selectLocation}
                fullWidth
                color="secondary"
                variant="outlined"
                placeholder="Location"
                name="location"
                sx={{ '& fieldset': { border: 'none' } }}
                onChange={_handleLocationChange}
                value={scheduleAddress}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected: any) => {
                  const location = storeLocation.find((item: any) => selected.id === item.id)
                  return location?.display_name
                }}
              >
                {storeLocation.map((item: any, i: number) => (
                  <MenuItem value={item} key={uuid4()}>{item?.display_name}</MenuItem>
                ))}
              </Select> :
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={Style.checkboxLabel}
                    onChange={handleSameAsContactAddress}
                    control={<BpCheckbox name="same_as_contact_address" checked={sameAsContactAddress} />}
                    label="Same As Contact Address"
                  />
                </Grid>
                {sameAsContactAddress === false &&
                  <Grid container spacing={2} className={Style.customerInfoForm}>
                    <Grid item xs={12}>
                      <InputLabel className={Style.inputLabel}>Project Address</InputLabel>
                      <TextField
                        fullWidth
                        placeholder="Street Address"
                        name="street_address"
                        onChange={handleLocationChange}
                        value={scheduleAddress?.street_address}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        placeholder="2nd Line"
                        name="second_line"
                        onChange={handleLocationChange}
                        value={scheduleAddress?.second_line}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={state}
                        getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                        onChange={(_, value: any) => handleAddressChange('state_province', getSafeValue(value, 'name', value))}
                        onInputChange={(_, newInputValue: string) => handleAddressChange('state_province', newInputValue)}
                        value={getSafeValue(scheduleAddress, 'state_province', '')}
                        isOptionEqualToValue={(option: any, value: any) => option.name === value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={Style.InputBorder}
                            size="small"
                            placeholder='State'
                          />
                        )}
                        fullWidth
                        freeSolo
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={cities}
                        getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                        onChange={(_, value: any) => handleAddressChange('city', getSafeValue(value, 'name', value))}
                        onInputChange={(_, newInputValue: string) => handleAddressChange('city', newInputValue)}
                        value={getSafeValue(scheduleAddress, 'city', '')}
                        isOptionEqualToValue={(option: any, value: any) => option.name === value}
                        renderInput={(params) => (
                          <TextField
                            className={Style.InputBorder}
                            {...params}
                            size="small"
                            placeholder='City'
                          />
                        )}
                        fullWidth
                        freeSolo
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        placeholder="Zip"
                        name="zipcode"
                        onChange={handleLocationChange}
                        value={scheduleAddress?.zipcode}
                      />
                    </Grid>
                  </Grid>}
              </>
            }
            <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>
              <PrimaryButton
                className={Style.saveChangeBtn}
                onClick={saveLocation}>Save
              </PrimaryButton>
            </Grid>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default ProjectAddress;
