/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setInitialJob } from 'redux/reducers/quickQuoteReducer';
import { getQuickQuote } from 'redux/selectors/quickQuoteSelector';
import uuid4 from 'uuid4';
import JobDetail from './JobDetail';

interface JobDetailsProps {
  errors: any
}

function JobDetails({ errors: validationError }: JobDetailsProps) {
  const dispatch = useAppDispatch(); 
  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));
  const { jobs: quickQuoteJob } = quickQuote
  
  useEffect(() => {
    if(quickQuoteJob.length === 0) {
      dispatch(setInitialJob())
    }
  }, []);

  return (
    <>
      <Box pt={3} pb={2}>
        <Typography variant='subTitle1'>
          Project Information
        </Typography>
      </Box>

      {quickQuoteJob.map((job: any, i: number) => (
        <React.Fragment key={`job_details_${i}`}>
          <JobDetail
            job={job}
            index={i}
            validationError={validationError}
          />
          {quickQuoteJob.length > (i + 1) && <hr />}
        </React.Fragment>
      ))}
    </>
  );
}

export default JobDetails;
