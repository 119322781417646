import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CarDetailsAPI from "api/resources/carDetails";
import FilmApi from "api/resources/film";
import OptionsAPI from "api/resources/option";
import UserApi from "api/resources/user";
import InnerLoader from "components/Loading/InnerLoader";
import { httpService } from "helper/httpService";
import { produce } from "immer";
import { useEffect, useReducer, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getCarMakesRequest } from "redux/reducers/carDetailsReducer";
import { getFilmsRequest } from "redux/reducers/filmReducer";
import { getOptionsRequest } from "redux/reducers/optionReducer";
import { resetQuoteState } from "redux/reducers/quickQuoteReducer";
import { getAllUsers } from "redux/reducers/userReducer";
import { selectQuickQuoteInfo } from "redux/selectors/quickQuoteSelector";
import { themeColor } from "theme/color";
import Applications from "./Applications/Applications";
import ContactDetails from "./ContactDetails/ContactDetails";
import ContactForm from "./ContactForm";
import CustomizedSteppers from "./CustomizedStepper";
import Footer from "./Footer/Footer";
import JobDetails from "./Job/JobDetails";
import Overview from "./Overview/Overview";
import styles from "./QuickQuote.module.scss";

const HeaderButton = styled(Button)(({ theme }) => ({
  color: themeColor.black,
  backgroundColor: themeColor.white,
  borderRadius: "15px",
  padding: "10px 23px",
  fontSize: "14px",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: themeColor.gray,
    color: themeColor.white,
  },
  textTransform: "capitalize",
}));

const quickQuoteState = {
  errors: {},
};

const quickQuoteReducer = produce((draft, { type, payload }) => {
  switch (type) {
    case "set_errors":
      draft.errors = payload.errors;
      break;
    default:
      break;
  }
});

function QuickQuoteDialog() {
  const [scheduleAppointment, setScheduleAppointment] = useState(false);
  const [state, localDispatch] = useReducer(quickQuoteReducer, quickQuoteState);
  const footerRef = useRef();
  const dispatch = useAppDispatch();

  const { loading, is_quick_quote_open, active_step } = useAppSelector((state) => selectQuickQuoteInfo(state));
  useEffect(() => {
    httpService(() => CarDetailsAPI.listMakes(), getCarMakesRequest, false);
    httpService(() => OptionsAPI.listInstallAreas(), getOptionsRequest, false);
    httpService(() => FilmApi.listAll(), getFilmsRequest, false);
    httpService(() => UserApi.getAllUsers(), getAllUsers, false);
  }, []);

  const steps = [
    "Contact Information",
    "Project Type",
    "Application & Price",
    "Contact Details",
    "Overview",
  ];

  const resetQuickQuoteModel = (status: boolean) => {
    dispatch(resetQuoteState(status));
    setErrors({});
  };

  const bookingAppointment = (value: any) => {
    setScheduleAppointment(value);
  };

  const handleClickOpen = () => {
    resetQuickQuoteModel(true)
  };

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      resetQuickQuoteModel(false);
    }
  };

  const setErrors = (errors: any) => {
    localDispatch({ type: "set_errors", payload: { errors } });
  };

  const getStepContents = (step: any) => {
    switch (step) {
      case 0:
        return {
          component: (
            <ContactForm errors={state?.errors} />
          ),
        };
      case 1:
        return {
          component: (
            <JobDetails errors={state?.errors} />
          ),
        };
      case 2:
        return {
          component: (
            <Applications errors={state?.errors} />
          ),
        };
      case 3:
        return {
          component: (
            <ContactDetails errors={state?.errors} />
          ),
        };
      case 4:
        return {
          component: (
            <Overview
              scheduleAppointment={scheduleAppointment}
              errors={state?.errors}
            />
          ),
        };
      default:
        return null;
    }
  };

  if (!is_quick_quote_open) {
    return <HeaderButton onClick={handleClickOpen}>Quick Quote</HeaderButton>;
  }

  return (
    <>
      <HeaderButton>Quick Quote</HeaderButton>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={is_quick_quote_open}
        onClose={handleClose}
        disableEnforceFocus
        PaperProps={{
          sx: {
            borderRadius: "10px", 
            width: '750px'
          }
        }}
      >
        {loading === true && <InnerLoader height="auto" />}
        {active_step < 5 && (
          <>
            <Grid>
              <CloseIcon className={styles.closeIcon} onClick={handleClose} />
            </Grid>
            <DialogContent className={styles.dialogContent}>
              <Box mb={"14px"}>
                <Typography
                  variant={"title"}
                  fontSize={"23px"}
                  fontWeight={600}
                >
                  Quick Quote
                </Typography>
              </Box>
              <CustomizedSteppers
                steps={steps}
                getStepContents={getStepContents}
              />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <Footer
                ref={footerRef}
                bookingAppointment={bookingAppointment}
                setErrors={setErrors}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default QuickQuoteDialog;
