import { Box, Typography } from "@mui/material";
import { RoundButton } from "components/Ui/Button/Button";
import { handleColor } from "components/Ui/Button/Tage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { formatTime, getSafeValue } from "utils";
import Style from "./Updates.module.scss";
import { convertTimeInTimeZone } from "utils/getCurrentTime";
import { useEffect, useState } from "react";

function ActiveBox({ isPublic, color }: any) {
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const organizationTimeZone = getSafeValue(publicData, 'organisation.timezone', 'UTC')
  const navigate = useNavigate()
  const campaign_id = getSafeValue(publicData, 'uuid', '')
  const handleNavigate = () => {
    if (isPublic) {
      const project_status = getSafeValue(publicData, 'project.status', '')
      if (project_status === 'Scheduled') {
        navigate(`/profile/${campaign_id}/quote-overview`)
      } else {
        navigate(`/profile/${campaign_id}/quote`)
      }
    }
  };

  const appointment = getSafeValue(publicData, 'project.appointment', false)
  const appointmentStart = formatTime(convertTimeInTimeZone(appointment.startsAt, organizationTimeZone))
  const appointmentEnd = formatTime(convertTimeInTimeZone(appointment.endsAt, organizationTimeZone))

  const [projectData, setProjectData] = useState<any>({
    status: 'Active',
    created_at: '04.02.23',
    name: 'Honda Accord (2019)',
    type: 'Automotive Window Tinting'
  })
  useEffect(() => {
    if (isPublic) {
      let projectType = `${getSafeValue(publicData, 'project.type', '')} Window Tinting`
      if (getSafeValue(publicData, 'project.services', []).length === 1 && getSafeValue(publicData, 'project.services[0].type', '') === 'Other') {
        projectType = getSafeValue(publicData, 'project.services[0].service_title', 'Other')
      }
  
      if (getSafeValue(publicData, 'project.services', []).length > 1) {
        projectType = `${projectType} +${getSafeValue(publicData, 'project.services', []).length - 1} More`
      }
      setProjectData({
        status: getSafeValue(publicData, 'project.status', ''),
        created_at: getSafeValue(publicData, 'project.created_at', '') ? moment(getSafeValue(publicData, 'project.created_at', '')).format('MM.DD.YY') : '',
        name: `${getSafeValue(publicData, 'project.type_details.make', '---')} ${getSafeValue(publicData, 'project.type_details.model', '---')} (${getSafeValue(publicData, 'project.type_details.year', '---')})`,
        type: projectType
      })
    }
  }, [publicData]);

  const getStatusMessage = (status: any) => {
    switch (status) {
      case 'Approved':
        return 'Waiting To Be Scheduled'
      case 'Rejected':
        return 'You Rejected The Quote'
      case 'Scheduled':
        return appointmentStart
      case 'Completed':
        return appointmentEnd
      default:
        return ''
    }
  }

  return (
    <Box className={Style.activeBox}>
      <Box className={Style.statusInfo} pb={3}>
        <Box className={Style.flex1}>
          <Typography variant="heading5" component={'h5'} fontWeight={'500'} color={handleColor(projectData.status).background}>{projectData.status}</Typography>
          <Typography variant="bodyText11px" fontWeight={'600'} color={handleColor(projectData.status).background}>{getStatusMessage(projectData.status)}</Typography>
        </Box>
        <Box className={Style.flex2}>
          <Typography variant={'bodyTextSmall'} fontWeight={'700'} color={handleColor(projectData.status).background}>{projectData.status}</Typography>
          <Typography variant={'bodyTextSmall'} fontWeight={'700'} color={'#000000'}>{projectData.created_at}</Typography>
        </Box>
      </Box>
      <Box className={Style.projectInfo}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <Typography variant="heading3" component={'h3'}>{projectData.name}</Typography>
            <Typography variant="inputText" fontWeight={'400'}>{projectData.type}</Typography>
          </Box>
          <Box>
            <RoundButton
              sx={{ backgroundColor: color, ':hover': { backgroundColor: color } }}
              onClick={() => handleNavigate()}
            >View Quote</RoundButton>
          </Box>
        </Box>
        {projectData.status === "Approved" &&
          <RoundButton
            fullWidth
            sx={{
              marginTop: '40px',
              backgroundColor: color
            }}
            onClick={() => window.open(`tel:900300400`, '_self')}
          >Call To Schedule</RoundButton>
        }
      </Box>
    </Box>
  );
}

export default ActiveBox;
