import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import PriceTooltip from "components/Ui/PriceTooltip";
import _ from "lodash";
import { useAppSelector } from "redux/hooks";
import Styles from "./Overview.module.scss";
import { getQuickQuote } from "redux/selectors/quickQuoteSelector";
import { getSafeValue } from "utils";

const QuoteTotal = ({ job, fromLongQuote = false }: any) => {
  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));

  const mobile_price = _.get(job, 'mobile_price', 0.00)
  const mobile_install = _.get(job, 'mobile_install', false)
  let hasDiscount: boolean = false

  let price: any = getSafeValue(quickQuote, 'amount.total_price_with_sales_tax', 0.00);
  let installPrice: any = getSafeValue(quickQuote, 'amount.total_price_with_sales_tax', 0.00) - getSafeValue(job, 'mobile_price', 0.00);
  let discount_price: any = getSafeValue(quickQuote, 'amount.total_price_with_discount', 0.00);

  const totals = [
    {
      type: 'Mobile Charge',
      price: mobile_price?.toFixed(2),
      is_display: mobile_install,
      font_color: '#000000',
      show_tooltip: false
    },
    {
      type: 'Install',
      price: installPrice?.toFixed(2),
      is_display: mobile_install,
      font_color: '#000000',
      show_tooltip: false
    },
    {
      type: hasDiscount ? 'Before Discount' : 'Total',
      price: price?.toFixed(2),
      is_display: true,
      font_color: '#000000',
      is_grand_total: !hasDiscount,
      show_tooltip: true
    },
    {
      type: 'Discounted Price',
      price: discount_price?.toFixed(2),
      is_display: hasDiscount,
      font_color: '#FF6969',
      is_grand_total: hasDiscount,
      show_tooltip: true
    },
  ]

  return <>
    {(fromLongQuote === true) ? <Box className={Styles.serviceLongQuotePrice} justifyContent={'flex-end !important'}>
      <Box display={'flex'} gap={'15px'}>
        {hasDiscount ? <>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Before Price
            </Typography>
            <Typography variant="title2" fontWeight={700}>
              ${price?.toFixed(2)}
            </Typography>
          </Box>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Discounted Price
            </Typography>
            <Typography variant="title2" fontWeight={700} color={'#FF6969'}>
              ${discount_price?.toFixed(2)}
            </Typography>
          </Box>
        </> :
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Total
            </Typography>
            <Typography variant="title2" fontWeight={700}>
              ${price?.toFixed(2)}
            </Typography>
          </Box>
        }
      </Box>
    </Box> : <Box pt={2}>
      <Table className={Styles.table}>
        <TableBody className={Styles.tableBody}>
          {totals.filter(total => total.is_display === true).map((total: any, index: number) => (
            <TableRow
              key={`total_${index}`}
              sx={{ "& td": { border: 0, padding: 0 } }}>
              <TableCell width={'85%'} align="right">
                <Typography
                  variant="subtitle1"
                  className={Styles.servicePriceTitle}
                >{total.type} {total.show_tooltip && <PriceTooltip position={'right'} />}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="subtitle1"
                  className={total.is_grand_total === true ? Styles.servicePrice : Styles.serviceSubPrice}
                  color={total.font_color}
                >${total.price}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>}
  </>
};

export default QuoteTotal;