/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./session/Auth";
import Application from "./session/Application";
import {
  DashboardPage,
  AnalyticsPage,
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  LeadPages,
  ContactsPages,
  CampaignsPages,
  ProfilesPage,
  CalendarPage,
  NoPage,
  ContactsViewPage,
  TodaysWorkPage,
  BusinessProfilesPage,
  TeamPage,
  SendQueuePages,
  FormsPages,
  CreateFormPage,
  LeadStatisticsPage,
  TasksPage,
  LeadFormPage,
  LeadProfilePage,
  Projects,
  Overview,
  Contact,
  Campaigns,
  Invoices,
  TimelineHistory,
  Reviews,
  NewProject,
  PublicProfilePage,
  TargetsPage,
  CalendarSettingPage,
  ViewQuotePage,
  NotificationSettingPage,
  InvoicingSettingPage,
  ResetPasswordPage,
  InvoicesPage,
  EmailVerifyPage,
  InvoicesPreview,
  NewProjectApplication,
  NewProjectOverview,
  NewProjectSchedule,
  ArchitecturalDetailedRoomMeasurements,
  InvoicesPayments,
  Adjusting,
  CampaignsView,
  ArchitecturalDetailedPricing,
  ArchitecturalDetailedAppointment,
  ProjectDetail,
  QuoteOverviewPage,
  QuoteUpgradePage,
  ReviewPage
} from "utils/pageListAsync";
import Cookies from "universal-cookie";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { authService } from "helper/AuthMethod";
import { httpService } from "helper/httpService";
import AuthAPI from "api/resources/auth";
import TeamAPI from "api/resources/team";
import { logOut, userSession } from "redux/reducers/authReducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import AutomotiveProfile from "components/LeadProfile/AutomotiveProfile/AutomotiveProfile";
import ArchitecturalProfile from "components/LeadProfile/ArchitecturalProfile/ArchitecturalProfile";
import ProfileLayout from "pages/Public/Profile/Layout";
import PublicLayout from "layout/Public";
import CreateLeadForm from "pages/LeadsPages/Forms/CreateLeadForm";
import { setPermissions } from "redux/reducers/permissionReducer";
import { HasPermission } from "components/HasPermission/HasPermission";

const cookies = new Cookies();
function App() {
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector((state: any) => state.auth.isLogin);

  useEffect((): any => {
    (async () => {
      const token = await cookies.get("access_token");
      if (token) {
        await authService(() => AuthAPI.getSessionUser(), userSession);
        await httpService(() => TeamAPI.getTeamPermissions(), setPermissions);
      } else {
        dispatch(logOut());
      }
    })();
  }, [isLogin]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Auth />}>
            <Route index element={<DashboardPage />} />

            {/* Business Pages */}
            <Route path="/business">
              <Route index element={<DashboardPage />} />
              <Route path="/business/analytics" element={
                <HasPermission
                  permission={['enable_financial_analytics']}
                  showMessage={true}
                ><AnalyticsPage /></HasPermission>
              } />
              {/* <Route path="/business/reports" element={<ReportsPage />} />
              <Route path="/business/inventory" element={<InventoryPage />} /> */}
              <Route path="/business/invoices" element={
                <HasPermission
                  permission={['enable_invoice_viewing']}
                  showMessage={true}
                ><InvoicesPage /></HasPermission>
              } />
            </Route>

            {/* Schedule Pages */}
            <Route path="/schedule">
              <Route
                index
                path="/schedule/calendar"
                element={<CalendarPage />}
              />
              <Route index path="/schedule/work" element={<TodaysWorkPage />} />
              <Route index path="/schedule/tasks" element={<TasksPage />} />
            </Route>

            {/* Leads Pages */}
            <Route path="/leads">
              <Route index element={<Navigate to="/leads/list" replace />} />
              <Route path="list" element={<LeadPages />} />
              <Route path="contacts">
                <Route index element={<ContactsPages />} />
                <Route element={<ContactsViewPage />}>
                  <Route path="overview" element={
                    <HasPermission
                      permission={['enable_contact_overview']}
                      showMessage={true}
                    ><Overview /></HasPermission>
                  } />
                  <Route path="projects" element={
                    <HasPermission
                      permission={['enable_contact_project_view']}
                      showMessage={true}
                    ><Projects /></HasPermission>
                  } />
                  <Route path="projects/project/:project_id/:tab" element={
                    <HasPermission
                      permission={['enable_contact_project_view']}
                      showMessage={true}
                    ><ProjectDetail /></HasPermission>
                  } />
                  <Route
                    path="projects/architectural-room-measurements"
                    element={<ArchitecturalDetailedRoomMeasurements />}
                  />
                  <Route
                    path="projects/architectural-pricing"
                    element={<ArchitecturalDetailedPricing />}
                  />
                  <Route
                    path="projects/architectural-appointment"
                    element={<ArchitecturalDetailedAppointment />}
                  />
                  <Route path="new-project" element={<NewProject />} />
                  <Route
                    path="new-project-application"
                    element={<NewProjectApplication />}
                  />
                  <Route
                    path="new-project-overview"
                    element={<NewProjectOverview />}
                  />
                  <Route
                    path="new-project-schedule"
                    element={<NewProjectSchedule />}
                  />
                  <Route path="contact" element={
                    <HasPermission
                      permission={['enable_contact_information_view']}
                      showMessage={true}
                    ><Contact /></HasPermission>
                  } />
                  <Route path="campaigns">
                    <Route index element={
                      <HasPermission
                        permission={['enable_contact_campaign']}
                        showMessage={true}
                      ><Campaigns /></HasPermission>
                    } />
                    <Route path="view" element={
                      <HasPermission
                        permission={['enable_contact_campaign']}
                        showMessage={true}
                      ><CampaignsView /></HasPermission>
                    } />
                  </Route>

                  <Route path="invoices">
                    <Route index element={
                      <HasPermission
                        permission={['enable_contact_invoice_view']}
                        showMessage={true}
                      ><Invoices /></HasPermission>
                    } />
                    <Route path="view" element={
                      <HasPermission
                        permission={['enable_contact_invoice_view']}
                        showMessage={true}
                      ><InvoicesPayments /></HasPermission>
                    } />
                    <Route path="adjusting" element={
                      <HasPermission
                        permission={['enable_contact_invoice_view']}
                        showMessage={true}
                      ><Adjusting /></HasPermission>
                    } />
                  </Route>
                  <Route
                    path="timeline-history"
                    element={
                      <HasPermission
                        permission={['enable_contact_timeline_history']}
                        showMessage={true}
                      ><TimelineHistory /></HasPermission>
                    } />
                  <Route path="reviews" element={
                    <HasPermission
                      permission={['enable_contact_review']}
                      showMessage={true}
                    ><Reviews /></HasPermission>
                  } />
                </Route>
              </Route>
              <Route path="forms" element={
                <HasPermission
                  permission={['enable_forms']}
                  showMessage={true}
                ><FormsPages /></HasPermission>
              } />
              <Route path="forms/create-form" element={
                <HasPermission
                  permission={['enable_form_creation']}
                  showMessage={true}
                ><CreateFormPage /></HasPermission>
              } />
              <Route path="forms/create-lead-form" element={
                <HasPermission
                  permission={['enable_form_creation']}
                  showMessage={true}
                ><CreateLeadForm /></HasPermission>
              } />
              <Route path="forms/statistics" element={
                <HasPermission
                  permission={['enable_form_stats']}
                  showMessage={true}
                ><LeadStatisticsPage /></HasPermission>
              } />
              <Route path="campaigns" element={
                <HasPermission
                  permission={['enable_campaigns']}
                  showMessage={true}
                ><CampaignsPages /></HasPermission>
              } />
              <Route path="campaigns/send-queue" element={
                <HasPermission
                  permission={['enable_campaigns']}
                  showMessage={true}
                ><SendQueuePages /></HasPermission>
              } />
              <Route path="k-profile" element={
                <HasPermission
                  permission={['enable_k_profile_view']}
                  showMessage={true}
                ><LeadProfilePage /></HasPermission>
              }>
                <Route
                  index
                  element={
                    <Navigate to="/leads/k-profile/automotive" replace />
                  }
                />
                <Route path="automotive" element={<AutomotiveProfile />} />
                <Route
                  path="architectural"
                  element={<ArchitecturalProfile />}
                />
              </Route>
            </Route>
            {/* Solutions Pages */}
            {/* <Route path="/solutions">
              <Route
                index
                path="/solutions/services"
                element={<SolutionsPage />}
              />
            </Route> */}
            {/* Settings Pages */}
            <Route path="/settings">
              <Route
                index
                path="/settings/profile"
                element={<ProfilesPage />}
              />
              <Route
                path="/settings/business-profile"
                element={<BusinessProfilesPage />}
              />
              <Route path="/settings/team" element={<TeamPage />} />
              <Route path="/settings/targets" element={<TargetsPage />} />
              <Route
                path="/settings/calendar"
                element={<CalendarSettingPage />}
              />
              <Route
                path="/settings/notifications"
                element={<NotificationSettingPage />}
              />
              <Route
                path="/settings/invoicing"
                element={<InvoicingSettingPage />}
              />
            </Route>
          </Route>
          <Route path="/" element={<Application />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>
          <Route path="/" element={<PublicLayout />}>
            <Route path="/invoice/:invoice_id" element={<InvoicesPreview />} />
            <Route
              path="/email-verify"
              element={<EmailVerifyPage type="Verify" />}
            />
            <Route
              path="/email-cancel"
              element={<EmailVerifyPage type="Cancel" />}
            />
            <Route path="/lead-form" element={<LeadFormPage />} />
            <Route path="/public-profile" element={<ProfileLayout />}>
              <Route index element={<PublicProfilePage />} />
              <Route path="quote" element={<ViewQuotePage />} />
            </Route>
            <Route path="/profile/:campaign_id" element={<ProfileLayout />}>
              <Route index element={<PublicProfilePage />} />
              <Route path="quote" element={<QuoteUpgradePage />} />
              <Route path="quote-overview" element={<QuoteOverviewPage />} />
              <Route path="upgrade" element={<QuoteUpgradePage />} />
            </Route>
            <Route path="/profile/:campaign_id/review" element={<ReviewPage />} />
          </Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
