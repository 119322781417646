import Loading from "components/Loading";
import loadable from "utils/loadable";

// Auth Page

export const LoginPage = loadable(() => import("pages/Login"), {
  fallback: <Loading />,
});

export const RegisterPage = loadable(() => import("pages/Register"), {
  fallback: <Loading />,
});

export const ForgotPasswordPage = loadable(
  () => import("pages/ForgotPassword"),
  {
    fallback: <Loading />,
  }
);
export const ResetPasswordPage = loadable(() => import("pages/ResetPassword"), {
  fallback: <Loading />,
});
export const EmailVerifyPage = loadable(
  () => import("pages/Public/EmailVerify"),
  {
    fallback: <Loading />,
  }
);

//  Auth Page End +++++

// Business Pages

export const DashboardPage = loadable(
  () => import("pages/BusinessPages/Dashboard"),
  {
    fallback: <Loading />,
  }
);
export const AnalyticsPage = loadable(
  () => import("pages/BusinessPages/Analytics"),
  {
    fallback: <Loading />,
  }
);
export const ReportsPage = loadable(
  () => import("pages/BusinessPages/Reports"),
  {
    fallback: <Loading />,
  }
);
export const InventoryPage = loadable(
  () => import("pages/BusinessPages/Inventory"),
  {
    fallback: <Loading />,
  }
);
export const InvoicesPage = loadable(
  () => import("pages/BusinessPages/Invoices"),
  {
    fallback: <Loading />,
  }
);

// Business Pages End +++++

// Schedule Page
export const TodaysWorkPage = loadable(
  () => import("pages/SchedulePages/Work"),
  {
    fallback: <Loading />,
  }
);

export const CalendarPage = loadable(
  () => import("pages/SchedulePages/Calendar"),
  {
    fallback: <Loading />,
  }
);

export const TasksPage = loadable(() => import("pages/SchedulePages/Tasks"), {
  fallback: <Loading />,
});

// Schedule Page End ++++++

// Lead Page

export const LeadPages = loadable(() => import("pages/LeadsPages/Leads"), {
  fallback: <Loading />,
});
export const ContactsPages = loadable(
  () => import("pages/LeadsPages/Contacts"),
  {
    fallback: <Loading />,
  }
);
export const FormsPages = loadable(() => import("pages/LeadsPages/Forms"), {
  fallback: <Loading />,
});

export const LeadProfilePage = loadable(
  () => import("pages/LeadsPages/LeadProfile"),
  {
    fallback: <Loading />,
  }
);
export const ContactsViewPage = loadable(
  () => import("pages/LeadsPages/Contacts/View/index"),
  {
    fallback: <Loading />,
  }
);
export const Projects = loadable(
  () =>
    import("pages/LeadsPages/Contacts/View/ContactDetails/Projects/Projects"),
  {
    fallback: <Loading />,
  }
);
export const Overview = loadable(
  () =>
    import("pages/LeadsPages/Contacts/View/ContactDetails/Overview/Overview"),
  {
    fallback: <Loading />,
  }
);
export const Contact = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Contact/Contact"),
  {
    fallback: <Loading />,
  }
);
export const Campaigns = loadable(
  () =>
    import("pages/LeadsPages/Contacts/View/ContactDetails/Campaigns/Campaigns"),
  {
    fallback: <Loading />,
  }
);
export const Invoices = loadable(
  () =>
    import("pages/LeadsPages/Contacts/View/ContactDetails/Invoices/Invoices"),
  {
    fallback: <Loading />,
  }
);
export const InvoicesPreview = loadable(
  () =>
    import("pages/LeadsPages/Contacts/View/ContactDetails/Invoices/Preview"),
  {
    fallback: <Loading />,
  }
);
export const TimelineHistory = loadable(
  () =>
    import(
      "pages/LeadsPages/Contacts/View/ContactDetails/TimelineHistory/TimelineHistory"
    ),
  {
    fallback: <Loading />,
  }
);
export const Reviews = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Reviews/Reviews"),
  {
    fallback: <Loading />,
  }
);

export const NewProject = loadable(
  () =>
    import(
      "pages/LeadsPages/Contacts/View/ContactDetails/NewProject/NewProject"
    ),
  {
    fallback: <Loading />,
  }
);

export const NewProjectApplication = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/NewProject/NewProjectApplication"),
  { fallback: <Loading /> }
);

export const NewProjectOverview = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/NewProject/NewProjectOverview"),
  { fallback: <Loading /> }
);

export const NewProjectSchedule = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/NewProject/NewProjectSchedule"),
  { fallback: <Loading /> }
);

export const ArchitecturalDetailedRoomMeasurements = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Projects/ArchitecturalDetailQuote/ArchitecturalDetailedRoomMeasurements"),
  { fallback: <Loading /> }
);

export const InvoicesPayments = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Invoices/View/InvoicesPayments"),
  { fallback: <Loading /> }
);

export const Adjusting = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Invoices/Adjusting/Adjusting"),
  { fallback: <Loading /> }
);

export const CampaignsView = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Campaigns/View/CampaignsView"),
  { fallback: <Loading /> }
);

export const ArchitecturalDetailedPricing = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Projects/ArchitecturalDetailQuote/ArchitecturalDetailedPricing"),
  { fallback: <Loading /> }
);

export const ArchitecturalDetailedAppointment = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/Projects/ArchitecturalDetailQuote/ArchitecturalDetailedAppointment"),
  { fallback: <Loading /> }
);

export const ProjectDetail = loadable(
  () => import("pages/LeadsPages/Contacts/View/ContactDetails/JobView/ProjectDetail"),
  { fallback: <Loading /> }
);

export const CreateFormPage = loadable(
  () => import("pages/LeadsPages/Forms/CreateForm"),
  {
    fallback: <Loading />,
  }
);

export const CreateLeadFormPage = loadable(
  () => import("pages/LeadsPages/Forms/CreateLeadForm"),
  {
    fallback: <Loading />,
  }
);

export const LeadStatisticsPage = loadable(
  () => import("pages/LeadsPages/Forms/LeadStatistics"),
  {
    fallback: <Loading />,
  }
);
export const LeadFormPage = loadable(() => import("pages/Public/LeadForm"), {
  fallback: <Loading />,
});
export const PublicProfilePage = loadable(
  () => import("pages/Public/Profile"),
  {
    fallback: <Loading />,
  }
);
export const ViewQuotePage = loadable(
  () => import("pages/Public/Profile/ViewQuote"),
  {
    fallback: <Loading />,
  }
);
export const QuoteOverviewPage = loadable(
  () => import("pages/Public/Profile/QuoteOverview"),
  {
    fallback: <Loading />,
  }
);

export const QuoteUpgradePage = loadable(
  () => import("pages/Public/Profile/QuoteUpgrade"),
  {
    fallback: <Loading />,
  }
);

export const ReviewPage = loadable(
  () => import("pages/Public/Profile/Review"),
  {
    fallback: <Loading />,
  }
);

export const CampaignsPages = loadable(
  () => import("pages/LeadsPages/Campaigns"),
  {
    fallback: <Loading />,
  }
);
export const SendQueuePages = loadable(
  () => import("pages/LeadsPages/Campaigns/SendQueue"),
  {
    fallback: <Loading />,
  }
);
// Lead Page End +++++

// Solutions Page

export const SolutionsPage = loadable(
  () => import("pages/SolutionsPages/Solutions"),
  {
    fallback: <Loading />,
  }
);

// Solutions Page End ++++

// Profiles Page

export const ProfilesPage = loadable(
  () => import("pages/SettingsPages/Profiles"),
  {
    fallback: <Loading />,
  }
);

export const BusinessProfilesPage = loadable(
  () => import("pages/SettingsPages/BusinessProfile"),
  {
    fallback: <Loading />,
  }
);

export const TeamPage = loadable(() => import("pages/SettingsPages/Team"), {
  fallback: <Loading />,
});

export const TargetsPage = loadable(
  () => import("pages/SettingsPages/Targets"),
  {
    fallback: <Loading />,
  }
);

export const CalendarSettingPage = loadable(
  () => import("pages/SettingsPages/Calendar"),
  {
    fallback: <Loading />,
  }
);

export const NotificationSettingPage = loadable(
  () => import("pages/SettingsPages/Notification"),
  {
    fallback: <Loading />,
  }
);

export const InvoicingSettingPage = loadable(
  () => import("pages/SettingsPages/Invoicing"),
  {
    fallback: <Loading />,
  }
);

// Setting Page End ++++
export const NoPage = loadable(() => import("pages/NoPage"), {
  fallback: <Loading />,
});
