import { createSelector } from 'reselect';
// Select dat from state
export const getOptions = (state: any) => state.options;

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getInstallAreas = createSelector(
  getOptions,
  (options) => options.installAreas?.map((installArea:any) => ({
    id: installArea.id,
    area: installArea.value,
    img: installArea.img,
    clashingAreas: installArea.clashingAreas || [],
    films: [
      {
        name: 'Unassigned', price: 0, discount_price: 0, discount_conditions: [], shade: ''
      }
    ],
  }))
);
