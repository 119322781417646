import { Box, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import LeadProfileApi from "api/resources/leadProfile";
import { httpService } from "helper/httpService";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import { refreshRequest } from "redux/reducers/leadProfileReducer";
import { getSafeValue } from "utils";
import Highlights from "../Projects/Highlights";
import Style from "./Aboutus.module.scss";
import { LuSave } from "react-icons/lu";
import Edit from "assets/icon/edit_icon_square.svg";

function AboutUs({ isPublic }: any) {
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const about_us_message = getSafeValue(data, 'about_us_message', "At [Your Company Name], we're more than just a window tinting service. With a dedication to precision and a passion for quality, we've been transforming vehicle aesthetics and enhancing driver comfort for over a decade. Our expert team utilizes state-of-the-art materials and cutting-edge techniques to ensure flawless results every time. Whether you're looking to protect your interiors, boost privacy, or simply elevate your ride's  style, trust in us to bring your vision to life.")

  const select = useAppSelector((state: any) => state.leadProfile.select);
  const [tagLine, setTagLine] = useState<any>(getSafeValue(data, 'organisation_tagline', ''))
  const [isEdit, setIsEdit] = useState(false)

  const handleSave = () => {
    if (isEdit) {
      const para = {
        ...data,
        organisation_tagline: tagLine,
      };
      delete para.alias_image;
      delete para.cover_picture;
      delete para.logo;
      httpService(() => LeadProfileApi.updateProfile(select, para), refreshRequest);
    }
    setIsEdit(!isEdit);
  };

  return (
    <Box>
      <Highlights />
      <Box mt={2} className={Style.container}>
        <Box>
          <Typography variant='heading4' fontWeight={'600'} component={'h5'}>Blacked Out Tinting</Typography>
          {!isPublic && isEdit === true ?
            <OutlinedInput
              placeholder="Tag Line"
              name="organisation_tagline"
              size="small"
              value={tagLine}
              onChange={(e: any) => setTagLine(e.target.value)}
              endAdornment={
                <InputAdornment position="end"><LuSave className="cursor" size={20} onClick={handleSave} /></InputAdornment>
              }
            /> : <Box display={'flex'} alignItems={'center'} gap={'5px'}>
              <Typography
                variant='heading6'
                fontWeight={'500'}
                contentEditable={isEdit}
                onInput={(e: any) => setTagLine(e.currentTarget.textContent)}
              >{tagLine}</Typography>
              <img src={Edit} alt="edit" className="cursor" onClick={() => setIsEdit(true)} />
            </Box>
          }
        </Box>
        <Typography variant='bodyTextNormal' fontWeight={'500'} mt={'15px'} component={'p'}>{about_us_message}</Typography>
      </Box>
    </Box>
  );
}

export default AboutUs;
