/* eslint-disable object-curly-newline */
import { Box, Fade, Modal, Typography } from "@mui/material";
import { ButtonBox, DarkButton, GreenButton, TextButton } from "components/Ui/Button/Button";
import { MdOutlineClose } from "react-icons/md";
import classes from "./Warning.module.scss";
import InnerLoader from "components/Loading/InnerLoader";

export default function Warning({
  open,
  close,
  handle,
  title,
  content,
  buttonText = 'Confirm',
  darkButton = false,
  loading = false
}: any) {
  const handleClose = () => {
    close(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={classes.paperModal}>
          {loading === true && <InnerLoader height={'auto'} />}
          <Box onClick={() => handleClose()} className={classes.close}>
            <MdOutlineClose size={20} style={{ cursor: 'pointer' }} />
          </Box>
          <Typography variant="heading4" component={'h4'} fontWeight={'600'}>
            {title}
          </Typography>
          <Typography
            variant="title3"
            className={classes.subtitle}
            pt={1}
            pb={2}
          >
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Typography>
          {darkButton === false ?
            <Box display={'flex'} justifyContent={'space-between'}>
              <ButtonBox
                variant="contained"
                onClick={() => handleClose()}
              >Go Back</ButtonBox>
              <GreenButton
                variant="contained"
                onClick={() => handle()}
              >
                {buttonText}
              </GreenButton>
            </Box> : <Box display={'flex'} justifyContent={'space-between'} mt={'15px'}>
              <TextButton
                className="btn-dark"
                onClick={() => handleClose()}
                sx={{
                  fontSize: '16px'
                }}
              >Cancel</TextButton>
              <DarkButton
                variant="contained"
                onClick={() => handle()}
              >
                {buttonText}
              </DarkButton>
            </Box>}
        </Box>
      </Fade>
    </Modal >
  );
}
