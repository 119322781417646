/* eslint-disable object-curly-newline */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Fade, Modal, Slider, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import AvatarDrag from 'assets/avatar_drag.svg';
import { DarkButton, PrimaryButton } from "components/Ui/Button/Button";
import { useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import { MdOutlineClose } from "react-icons/md";
import classes from "./AvatarEditorModal.module.scss";

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: '#D9D9D9',
  height: 6,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)',
    }
  }
}));

export default function AvatarEditorModal({
  open,
  close,
  handle,
  image,
  title = 'Update Profile Picture',
  subTitle = '',
  height = 250,
  width = 250,
  buttonText = 'Update Profile Picture',
  buttonType = 'dark'
}: any) {
  const [imageScale, setImageScale] = useState<any>(1)
  const handleClose = () => {
    close(false);
  };
  const editor = useRef<any>(null);

  const handleImageScaleChange = (action: string) => {
    if (action === 'add' && imageScale < 2) {
      setImageScale(imageScale + 0.25)
    }

    if (action === 'remove' && imageScale > 1) {
      setImageScale(imageScale - 0.25)
    }
  }

  const updateProfileImage = async () => {
    if (editor) {
      const canvasScaled = editor.current.getImageScaledToCanvas().toDataURL()
      const result = await fetch(canvasScaled)
      const blob = await result.blob()
      const file = new File([blob], image.name, { type: image.type })

      const data = {
        base64: canvasScaled,
        blobUrl: window.URL.createObjectURL(blob),
        file: file
      }
      handle(data)
      handleClose()
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={classes.paperModal}>
          <Box onClick={() => handleClose()} className={classes.close}>
            <MdOutlineClose className="cursor" size={20} />
          </Box>
          <Box className={classes.title}>
            <Typography variant="heading1" component={'h1'}>{title}</Typography>
            <Typography variant="heading6" component={'h6'}>{subTitle}</Typography>
          </Box>
          <Box>
            <Box className={classes.avatarEditorWrapper}>
              <AvatarEditor
                ref={editor}
                image={image}
                width={width}
                height={height}
                border={60}
                scale={Number(imageScale)}
                rotate={0}
              />
              <div className={classes.dragToReposition}>
                <img src={AvatarDrag} alt='avatar' />
                Drag to reposition
              </div>
            </Box>
            <Stack spacing={2} direction="row" sx={{ my: 2 }} alignItems="center">
              <RemoveIcon
                className={classes.icon}
                onClick={() => handleImageScaleChange('remove')} />
              <RangeSlider
                min={1}
                max={2}
                step={0.01}
                value={imageScale}
                onChange={(e: any) => setImageScale(e.target.value)}
              />
              <AddIcon
                className={classes.icon}
                onClick={() => handleImageScaleChange('add')} />
            </Stack>
            {buttonType === 'dark' &&
              <DarkButton
                type="button"
                fullWidth
                onClick={updateProfileImage}
              >{buttonText}</DarkButton>
            }
            {buttonType === 'primary' &&
              <PrimaryButton
                type="button"
                fullWidth
                onClick={updateProfileImage}
              >{buttonText}</PrimaryButton>
            }
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
