import * as React from "react";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Slider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { MdOutlineClose } from "react-icons/md";
import Style from "./UpdateProfile.module.scss";
import { PrimaryButton } from "components/Ui/Button/Button";
import { FcMinus } from "react-icons/fc";
import { IoMdAdd } from "react-icons/io";
import { Stage, Layer, Circle, Image, Rect } from "react-konva";
import useImage from "use-image";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LeadProfileApi from "api/resources/leadProfile";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import html2canvas from 'html2canvas';
import { refreshRequest } from "redux/reducers/leadProfileReducer";


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function UpdateProfile({ open, handleClose }: any) {
  const imgRef: any = React.useRef(null);
  const whiteImage: any = React.useRef(null);
  const blackImage: any = React.useRef(null);
  const [companyLogo, setCompanyLogo] = React.useState<any>(null);
  const select = useAppSelector((state: any) => state.leadProfile.select);
  const dispatch = useAppDispatch()
  const [canvas, setCanvas] = React.useState({
    width: 630,
    height: 440,
  });
  const [dimension, setDimension] = React.useState({
    width: 440,
    height: 440,
  });
  const [recDimension, setRecDimension] = React.useState({
    width: 0,
    height: 0,
  });
  const [imgInfo, setImgInfo] = React.useState({
    width: 0,
    height: 0,
  });
  const [value, setValue] = React.useState<number>(dimension.height / 2 - 30);
  const [imgBg, setImgBg] = React.useState(false);
  const [imgView, setImgView] = React.useState(null);
  const [axis, setAxis] = React.useState({
    x: 0,
    y: 0,
  });
  const [reactAxis, setReactAxis] = React.useState({
    x: 0,
    y: 0,
  });
  const [dragging, isDragging] = React.useState(false);
  const [load, isLoad] = React.useState(false);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const [image]: any = useImage(companyLogo);
  React.useEffect(() => {
    if (image) {
      const wrh = image.width / image.height;
      let newWidth = value;
      let newHeight = value / wrh;

      if (newHeight > dimension.height) {
        newHeight = dimension.height;
        newWidth = newHeight * wrh;
      }

      const xOffset =
        newWidth < dimension.width ? (dimension.width - newWidth) / 2 : 0;

      const yOffset =
        newHeight < dimension.height ? (dimension.height - newHeight) / 2 : 0;
      setImgInfo({
        width: newWidth,
        height: newHeight,
      });
      setAxis({
        x: xOffset,
        y: yOffset,
      });

      const recWidth = dimension.height;
      const recHeight = dimension.height;
      setRecDimension({
        width: recWidth,
        height: recHeight,
      });
      setReactAxis({
        x: dimension.width / 2 - recWidth / 2,
        y: dimension.height / 2 - recHeight / 2,
      });
      isLoad(!load);
    }
  }, [image, value]);

  const handleUploadImg = async () => {
    if (imgRef.current) {
      const stage = imgBg
      ? whiteImage.current
      : blackImage.current;
      const canvas = await html2canvas(stage);
      const dataURL = canvas.toDataURL();
      fetch(dataURL)
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], "logo.png", {
            type: "image/png",
          });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", "Logo");
          try {
            await LeadProfileApi.updateLogo(select, formData);
            handleClose()
            dispatch(refreshRequest())
          } catch (e) {
            console.log(e);
          }
        });
    }
  };

  const handleExport = () => {
    if (imgRef) {
      const uri = imgRef?.current?.toDataURL();
      setImgView(uri);
    }
  };
  const handleImage = (e: any) => {
    setCompanyLogo(URL.createObjectURL(e.target.files[0]));
  };
  React.useEffect(() => {
    handleExport();
  }, [image, value, dragging, load]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "10px",
          maxWidth: "100%",
          minWidth: 400,
        },
      }}
    >
      <DialogContent>
        <Box>
          <Box onClick={() => handleClose()} className={Style.close}>
            <MdOutlineClose className="cursor" size={25} color="#A4A4A4" />
          </Box>
          <Typography variant="title" className={Style.title}>
            Update Profile Picture
          </Typography>
          {!companyLogo ? (
            <Box>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/png"
                  onChange={handleImage}
                />
              </Button>
            </Box>
          ) : (
            <Box>
              <Box
                width={canvas.width}
                height={canvas.height}
                style={{
                  backgroundColor: imgBg ? "#fff" : "#000",
                }}
                className="convas"
              >
                <Stage width={dimension.width} height={dimension.height}>
                  <Layer>
                    <Circle
                      x={dimension.width / 2}
                      y={dimension.height / 2}
                      radius={dimension.height / 2 - 30}
                      stroke={imgBg ? "#000" : "#fff"}
                      strokeWidth={3}
                    />
                  </Layer>
                  <Layer ref={imgRef}>
                    <Rect
                      x={reactAxis.x}
                      y={reactAxis.y}
                      width={recDimension.width}
                      height={recDimension.height}
                    />
                    {image && (
                      <Image
                        image={image}
                        x={axis.x}
                        y={axis.y}
                        width={imgInfo.width}
                        height={imgInfo.height}
                        draggable
                        onDragStart={() => {
                          isDragging(true);
                        }}
                        onDragEnd={(e) => {
                          isDragging(false);
                          setAxis({
                            x: e.target.x(),
                            y: e.target.y(),
                          });
                        }}
                      />
                    )}
                  </Layer>
                </Stage>
              </Box>
              <Box pt={2}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <FcMinus size={25} color="#000" />
                  <Slider
                    aria-label="Zoom"
                    value={value}
                    max={dimension.height}
                    onChange={handleChange}
                  />
                  <IoMdAdd size={25} color="#000" />
                </Stack>
              </Box>
              <Box className={Style.flexRow} pt={2}>
                <Box>
                  <Box className={Style.imgBox}>
                    {/* {imgView && <img src={imgView} className={Style.imgBlack} />} */}
                    <Box
                      ref={blackImage}
                      className={Style.imgBlack}
                      bgcolor={"#000"}
                      onClick={() => setImgBg(false)}
                    >
                      {imgView && (
                        <img src={imgView} className={Style.imgView} />
                      )}
                    </Box>
                    <Box
                      ref={whiteImage}
                      className={Style.imgWhite}
                      bgcolor={"#fff"}
                      onClick={() => setImgBg(true)}
                    >
                      {imgView && (
                        <img src={imgView} className={Style.imgView} />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography className={Style.title2}>
                    Choose Background Color
                  </Typography>
                  <Typography className={Style.para}>
                    This color will display on your quote <br /> viewable to the
                    customer
                  </Typography>
                </Box>
              </Box>
              <Box pt={2.5}>
                <PrimaryButton
                  type="submit"
                  fullWidth
                  onClick={() => handleUploadImg()}
                >
                  Save Changes
                </PrimaryButton>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateProfile;
