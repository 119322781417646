import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import allInvoice from "assets/tabs/all_invoice.svg";
import overdueInvoice from "assets/tabs/overdue_invoice.svg";
import paidInvoice from "assets/tabs/paid_invoice.svg";
import unpaidInvoice from "assets/tabs/unpaid_invoice.svg";
import _ from 'lodash'
import moment from 'moment'
import { RxLetterCaseCapitalize } from "react-icons/rx";
import { getSafeValue } from "utils";

interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  invoiceTabs: any;
  activeTab: any;
}

const initialState: CounterState = {
  refresh: false,
  entities: [],
  meta: null,
  activeTab: {
    name: "All Invoices",
    filterValue: "",
    key: "all_invoices",
    headText: "All Invoices",
    image: allInvoice,
    selected: false,
  },
  invoiceTabs: [
    {
      name: "All Invoices",
      filterValue: "",
      key: "all_invoices",
      headText: "All Invoices",
      image: allInvoice,
      selected: false,
    },
    {
      name: "Paid Invoices",
      filterValue: "Paid",
      key: "paid_invoices",
      headText: "Paid Invoices",
      image: paidInvoice,
      selected: false,
    },
    {
      name: "Unpaid Invoices",
      filterValue: "Unpaid",
      key: "unpaid_invoices",
      headText: "Unpaid Invoices",
      image: unpaidInvoice,
      selected: false,
    },
    {
      name: "Overdue Invoices",
      filterValue: "Overdue",
      key: "overdue_invoices",
      headText: "Overdue Invoices",
      image: overdueInvoice,
      selected: false,
    }
  ]
};

const getFontColor = (status: string, isPaidAmount: boolean = false) => {
  switch (status) {
    case 'Paid':
      return isPaidAmount ? '#43CC2B' : '#000'
    case 'Unpaid':
      return isPaidAmount ? '#FF7800' : '#000'
    case 'Overdue':
      return '#EC2227'
    default:
      return '#000000'
  }
}

export const invoicePageReducer = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    invoiceRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data.map((data: any) => {
        let customerName = _.get(data, 'contact.first_name', '')
        if (customerName === '' || customerName === null) {
          customerName = _.get(data, 'contact.name', '')
        } else {
          customerName = `${_.get(data, 'contact.first_name', '')} ${_.get(data, 'contact.last_name', '')}`
        }
        let projectType = `${_.get(data, "project.project_type", "")} Window Tinting`;
        let projectDetails = _.get(data, "project.project_type", "");
        if (projectType === "Architectural") {
          projectDetails = `${_.get(data, "project.type.building_type", "")} ${_.get(data, "project.type.service_type", "")}`;
        } else {
          projectDetails = `${_.get(data, "project.type.year", "")} ${_.get(data, "project.type.model", "")} ${_.get(data, "project.type.make", "")}`;
        }

        if (getSafeValue(data, 'project.project_services', []).length === 1 && getSafeValue(data, 'project.project_services[0].type', '') === 'Other') {
          projectType = getSafeValue(data, 'project.project_services[0].service_title', 'Other')
        }
    
        if (getSafeValue(data, 'project.project_services', []).length > 1) {
          projectType = `${projectType} +${getSafeValue(data, 'project.project_services', []).length - 1} More`
        }

        let dueDate = data.due_at ? moment(data.due_at).format('MM.DD.YY') : ''
        if (data.invoice_status === 'Overdue' || data.invoice_status === 'Unpaid') {
          dueDate = moment(data.due_at).fromNow(true)
        }

        const issuedDate = data.created_at ? moment.utc(data.created_at).format('MM.DD.YY') : ''

        return {
          project_id: data.project.id,
          status: data.invoice_status,
          invoice_number: data.id,
          issued: issuedDate,
          due: {
            status: data.invoice_status,
            date: dueDate,
            color: data.invoice_status === 'Paid' ? '#000' : getFontColor(data.invoice_status, true),
            terms: `${data.invoice_terms || ''} Payment Terms from ${moment(data.due_at).format('MM/DD/YY')}`
          },
          customer: {
            name: customerName,
            type: 'Private Customer'
          },
          project: {
            name: `${projectType} `,
            type: projectDetails
          },
          paid: {
            amount: `$${Number(data.total_paid).toFixed(2)}`,
            color: getFontColor(data.invoice_status, true)
          },
          balance: {
            amount: `$${Number(data.balance_due).toFixed(2)}`,
            color: getFontColor(data.invoice_status, false)
          },
          cost: {
            amount: `$${Number(data.total).toFixed(2)}`,
            color: getFontColor(data.invoice_status, false)
          },
          redirectData: {
            customer_id: data.contact_id,
            project_id: data.project_id
          }
        }
      });
      state.meta = action.payload.meta;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      state.invoiceTabs = [
        ...state.invoiceTabs.map((item: any) => ({ ...item, selected: false })),
      ];
      const key = action.payload;
      const index = state.invoiceTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.invoiceTabs[index];
        state.invoiceTabs = state.invoiceTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.invoiceTabs[0];
        state.invoiceTabs[0].selected = true;
      }
    },
  },
});

export const { invoiceRequest, refreshRequest, selectTabs } = invoicePageReducer.actions;

export default invoicePageReducer.reducer;
